import { Grid, Typography, Box } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loading from '../Admin/Loading';
import { error } from 'console';
import { DataGrid } from '@mui/x-data-grid';
import { FormatNumber } from '../../utils/formatNumber';

function All_Transaction({ accessToken }) {
    const [isLoading, setLoading] = useState(false);
    const [isSucc, setSucc] = useState(false);
    const [isFail, setFail] = useState(false);
    const [msg, setMsg] = useState("");
    const [Transaction, setTrasaction] = useState<any>([]);
    const [columns, setColumns] = useState([
        {
            field: 'id', headerName: 'S.No', width: 90, renderHeader: () => (
                <strong>
                    {'S.No'}
                </strong>
            ),
        },
        {
            field: 'INVESTOR_NAME', headerName: 'Fund', width: 180, renderHeader: () => (
                <strong>
                    {'Fund'}
                </strong>
            ),
            renderCell: (params) => {
                let { INVESTOR_NAME } = params.row;
                return <div style={{ minHeight: "50px", alignItems: "center", display: "flex", width: "100%" }}>{INVESTOR_NAME}</div>
            }
        },
        {
            field: 'SCHEME_NAME', headerName: 'Fund', width: 380, renderHeader: () => (
                <strong>
                    {'Fund'}
                </strong>
            ),
            renderCell: (params) => {
                let { SCHEME_NAME } = params.row;
                const schemName = SCHEME_NAME.split("/")[1]
                return <div style={{ minHeight: "50px", alignItems: "center", display: "flex", width: "100%" }}>{schemName}</div>
            }
        },
        {
            field: 'TRXN_TYPE',
            headerName: 'Transaction Type',
            width: 240, renderHeader: () => (
                <strong>
                    {'Transaction Type'}
                </strong>
            ),
            renderCell: (params) => {
                let { TRXN_TYPE, PAYMENT_MODE } = params.row;
                const TransactionType = TRXN_TYPE.includes("Purchase") ? "Purchase" : TRXN_TYPE.includes("Redemption") ? "Redemption" : "";
                return <div>{TransactionType}</div>;
            },
        },
        {
            field: 'UNITS', headerName: 'Amount', width: 180, renderHeader: () => (
                <strong>
                    {'Amount / Units'}
                </strong>
            ), renderCell: (params) => {
                const { UNITS, TRXN_STATUS,AMOUNT,TRXN_TYPE,ALL_UNITS } = params.row;
                const amount = TRXN_TYPE == "Redemption"?(ALL_UNITS == 'No'?`${FormatNumber(UNITS)} units`:`All units`):`Rs ${FormatNumber(AMOUNT)}`;
                if (TRXN_STATUS == 'Pending') {
                    // @ts-ignore
                    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} ><Typography variant='body1'>{amount}</Typography><Typography style={{ color: '#FDD017', textWrap: "wrap" }} variant='caption'>{TRXN_STATUS}</Typography></Box>
                } else if (TRXN_STATUS == "Rejected / Reversal") {
                    // @ts-ignore
                    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}><Typography variant='body1' >{amount}</Typography><Typography style={{ color: 'red', textWrap: "wrap" }} variant='caption'>{TRXN_STATUS.split("/")[0]}</Typography></Box>
                } else {
                    // @ts-ignore
                    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} ><Typography variant='body1' >{amount}</Typography><Typography style={{ color: 'green', textWrap: "wrap" }} variant='caption'>{TRXN_STATUS}</Typography></Box>
                }
            }
        },
        {
            field: 'AUTHORIZED_DATE_TIME', headerName: 'Transaction Date', width: 240, renderHeader: () => (
                <strong>
                    {'Transaction Date'}
                </strong>
            ),
        },
    ]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_BACKEND_HOST}v1/super/nse/transactions/summary`, { headers: { Authorization: `Bearer ${accessToken}` } }
            )
            .then(({ data }) => {
                if (!data.succ) {
                    setFail(true);
                    setMsg(data.message);
                    setLoading(false)
                    return;
                }
                setSucc(true);
                setMsg(data.message);
                setTrasaction(data.Transaction);
                setLoading(false)
                return;
            }).catch((error) => {
                console.log(error);
                setFail(true);
                setMsg("Internal Server Error")
                setLoading(false)
                return;
            });
    }, [])

    return isLoading ? <Loading /> : (
        <Grid container sx={{
            width: "100%", padding: {
                xs: 1, sm: 2
            }
        }}>
            <Grid item sx={{ textAlign: "center", width: "100%", mb: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: "600" }} >A summary of all transactions for all investors for the last 10 days</Typography>
            </Grid>
            <Grid item sx={{ height: "84vh", width: '100vw' }} >
                <div style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        sx={{ mt: 1 }}
                        getRowHeight={() => 'auto'}
                        hideFooter={true}
                        rowsPerPageOptions={[50, 100, 1000]}
                        rows={Transaction.map((each: any, idx: number) => {
                            return { ...each, id: idx + 1 };
                        })}
                        columns={columns.map((each, idx) => {
                            return {
                                id: idx + 1,
                                ...each, headerAlign: 'center',
                                align: 'center',
                                sx: {
                                    size: 4,
                                },
                            }
                        })}
                    />
                </div>
            </Grid>
        </Grid>
    )
}

export default All_Transaction