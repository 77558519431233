import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SouthTwoToneIcon from "@mui/icons-material/SouthTwoTone";
import NorthTwoToneIcon from "@mui/icons-material/NorthTwoTone";
import { useAppContext } from "../../../Store/AppContext";

const SchemeList = ({ schemes, sortConfig, setSortConfig }) => {
  const navigate = useNavigate();
  const [storeState, dispatch] = useAppContext();
  // const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  // Sorting logic inside useMemo to avoid unnecessary re-sorting
  const sortedSchemes = useMemo(() => {
    if (!sortConfig.key) return schemes;

    return [...schemes].sort((a, b) => {
      const keyA = sortConfig.key.split(".").reduce((o, i) => o[i], a); // handle nested keys like 'returns.one_year'
      const keyB = sortConfig.key.split(".").reduce((o, i) => o[i], b);

      if (keyA < keyB) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (keyA > keyB) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [sortConfig]);

  const handleSort = (key: string) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <NorthTwoToneIcon fontSize="small" />
      ) : (
        <SouthTwoToneIcon fontSize="small" />
      );
    }
    return null;
  };

  // const columns = [
  //   {
  //     field: "scheme_name",
  //     headerName: "Equity Index",
  //     width: 400,
  //     renderHeader: () => <strong>{"Equity Index"}</strong>,
  //     renderCell: (params) => {
  //       let { scheme_name } = params.row;
  //       return <div>{scheme_name}</div>;
  //     },
  //   },
  //   {
  //     field: "corpus_in_crs",
  //     headerName: " AUM (In Crs.)",
  //     width: 200,
  //     renderHeader: () => <strong>{" AUM (In Crs.)"}</strong>,
  //     renderCell: (params) => {
  //       let { corpus_in_crs } = params.row;
  //       return (
  //         <div style={{ textAlign: "left" }}>{corpus_in_crs.toFixed()}</div>
  //       );
  //     },
  //   },
  //   {
  //     field: "one_year",
  //     headerName: " 1 Yr return",
  //     width: 200,
  //     renderHeader: () => <strong>{" 1 Yr return"}</strong>,
  //     renderCell: (params) => {
  //       let { one_year } = params.row.returns;
  //       return <div> {one_year ? `${one_year.toFixed(2)}%` : "-"}</div>;
  //     },
  //   },
  //   {
  //     field: "exit_date",
  //     headerName: "Age",
  //     width: 200,
  //     renderHeader: () => <strong>{"Age"}</strong>,
  //     renderCell: (params) => {
  //       const start_date = new Date(params.row.inception_date);
  //       const current_date = new Date();

  //       let years = current_date.getFullYear() - start_date.getFullYear();
  //       let months = current_date.getMonth() - start_date.getMonth();

  //       if (months < 0) {
  //         years--;
  //         months += 12;
  //       }

  //       const age = `${years}y ${months}m`;

  //       return <div>{age}</div>;
  //     },
  //   },
  //   {
  //     field: "",
  //     headerName: "",
  //     width: 120,

  //     renderCell: (params) => {
  //       let { quantity } = params.row;

  //       return (
  //         <div
  //           style={{
  //             padding: "10px",
  //             width: "100px",
  //             borderRadius: "5px",
  //             textAlign: "center",
  //             color: "white",
  //             backgroundColor: "#ffa500",
  //             cursor: "pointer",
  //           }}
  //         >
  //           Buy More
  //         </div>
  //       );
  //     },
  //   },
  // ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow sx={{ background: "#E8E9EB" }}>
            <TableCell>
              <Typography>Equity Index</Typography>
            </TableCell>
            <TableCell
              align="center"
              onClick={() => handleSort("corpus_in_crs")}
              sx={{ cursor: "pointer" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  justifyContent: "center",
                }}
              >
                <Typography> AUM (In Crs.)</Typography>{" "}
                <Typography> {getSortIcon("corpus_in_crs")}</Typography>
              </div>{" "}
            </TableCell>
            <TableCell
              align="center"
              onClick={() => handleSort("returns.one_year")}
              sx={{ cursor: "pointer" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  justifyContent: "center",
                }}
              >
                <Typography> 1 Yr return</Typography>{" "}
                <Typography> {getSortIcon("returns.one_year")}</Typography>
              </div>{" "}
            </TableCell>
            <TableCell align="center">
              <Typography> Age</Typography>
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedSchemes.map((row: any) => {
            const start_date = new Date(row.inception_date);
            const current_date = new Date();

            let years = current_date.getFullYear() - start_date.getFullYear();
            let months = current_date.getMonth() - start_date.getMonth();

            if (months < 0) {
              years--;
              months += 12;
            }

            const age = `${years}y ${months}m`;

            return (
              <TableRow
                key={row.id || row.name}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    background: "#E8E9EB",
                  },
                }}
              >
                <TableCell
                  sx={{ cursor: "pointer" }}
                  component="th"
                  scope="row"
                  onClick={() => navigate(`/dashboardAdmin/explore/${row.id}`)}
                >
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  {row.corpus_in_crs.toFixed()}
                </TableCell>
                <TableCell align="center">
                  {row.returns.one_year
                    ? `${row.returns.one_year.toFixed(2)}%`
                    : "-"}
                </TableCell>
                <TableCell align="center">{age}</TableCell>
                <TableCell align="center">
                  {row.is_purchasable && (
                    <div
                      style={{
                        padding: "10px",
                        width: "100px",
                        borderRadius: "5px",
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "#ffa500",
                        cursor: "pointer",
                      }}
                      onClick={(e) =>
                        navigate(
                          `/dashboardAdmin/nse/order/${storeState.ACTIVEINVETOR.folio.Folio}`,
                          { state: { value: row } }
                        )
                      }
                    >
                      Buy Now
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    // <Box
    //   sx={{
    //     height: "85vh",
    //     width: { xs: "96vw", md: "79vw" },
    //     display: { xs: "none", sm: "block" },
    //   }}
    // >
    //   <DataGrid
    //     sx={{ mt: 1 }}
    //     getRowHeight={() => "auto"}
    //     //  hideFooter={true}
    //     // rowsPerPageOptions={[50, 100, 1000]}
    //     rows={schemes.map((each: any, idx: number) => {
    //       return { ...each, id: idx + 1 };
    //     })}
    //     columns={columns.map((each, idx) => {
    //       return {
    //         id: idx + 1,
    //         ...each,
    //         headerAlign: "center",
    //         align: "center",
    //         sx: {
    //           size: 4,
    //         },
    //       };
    //     })}
    //   />
    // </Box>
  );
};

export default SchemeList;
