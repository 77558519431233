import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const exportToCSV = (csvDataTradeBook, csvDataTradeWise, UserDetail) => {
  try {
    const modifiedDataBook = csvDataTradeBook?.map((item) => {
      return {
        "Scheme": item.Fund_Description,
        "Transaction Date": item.Transaction_Date,
        "Trade Type": item.Transaction_Description,
        "Quantity": item.Units,
        "Price": item.Nav,
        "Value": item.Units * item.Nav,
      };
    });
    const modifiedDataWise = csvDataTradeWise?.map((item) => {
      return {
        Scheme: item.scheme,
        "Entry Date": item.entry_date,
        "Exit Date": item.exist_date,
        "Quantity": item.quantity,
        "Buy Value": item.buy_value,
        "Sell Value": item.sell_value,
        "Profit": item.profit,
        "Period of Holding (in days)": item.period_of_holding,
        "Taxable Profit (Short Term)": item.taxable_profit_short,
        "Taxable Profit (Long Term)": item.taxable_profit_long,
      };
    });

    const FromDate = UserDetail.Interval[0]?.split("-").reverse().join("-");
    const ToDate = UserDetail.Interval[1]?.split("-").reverse().join("-");
    const userData = [
      { KLARFIN: "" },
      { KLARFIN: "Name", " ": UserDetail.NAME },
      { KLARFIN: "PAN", " ": UserDetail.PAN },
      { KLARFIN: `Report from ${FromDate} to ${ToDate}`},
      {},
      {},
    ];

    const ws1 = XLSX.utils.json_to_sheet(userData);
    const ws2 = XLSX.utils.json_to_sheet(userData);

    ws1["!cols"] = [{ width: 55,fontWeight:700 }, { width: 20 }];
    ws2["!cols"] = [{ width: 55 }, { width: 20 }];

    XLSX.utils.sheet_add_json(ws1, modifiedDataBook, { origin: -1 });

    XLSX.utils.sheet_add_json(ws2, modifiedDataWise, { origin: -1 });

    var wb = XLSX.utils.book_new();
    const wb1 = { Sheets: ws1, SheetNames: ["Trade Book"] };
    const wb2 = { Sheets: { data: ws2 }, SheetNames: ["Trade Wise Exit"] };

    XLSX.utils.book_append_sheet(wb, ws2, "Trade Wise Exit", true); // Sheet2
    XLSX.utils.book_append_sheet(wb, ws1, "Trade Book", true); // Sheet2

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      "Transactions Reports-" + new Date().toLocaleDateString() + fileExtension
    );
  } catch (error) {
    throw new Error(error);
  }
};
