import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Stack,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Issuer {
  id: string;
  name: string;
  logo: string;
  website: string;
}

const IssuerHeader = ({ accessToken }: { accessToken: string }) => {
  const { id } = useParams<{ id: string }>();
  const [issuer, setIssuer] = useState<Issuer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Local object with title mappings based on issuer id
  const issuerTitles = {
    shriram: "Top Rated NBFC with 1 Crore customers",
    mahindra: "Top Rated NBFC with 1 Crore customers",
    bajaj: "The highest rated NBFC in India with 7 Crore customers",
  };

  const fetchIssuers = async () => {
    try {
      const response = await axios.get(
        "http://localhost:8081/v1/user/fixeddeposit/getissuers",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const issuersData = response.data.issuers;
      const matchedIssuer = issuersData.find(
        (issuer: Issuer) => issuer.id === id
      );
      setIssuer(matchedIssuer || null);
    } catch (err) {
      setError("Failed to fetch issuer data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssuers();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!issuer) {
    return <Typography>No issuer data found</Typography>;
  }

  return (
    <Stack spacing={2}>
      <Card elevation={2}>
        <CardContent>
          <Stack direction="row" spacing={2} gap={2} alignItems="center">
            <Box
              sx={{
                width: '200px',
                height: '100px',
                margin: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              <img
                src={issuer.logo}
                alt={issuer.name}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            </Box>
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '36px', color:'#0E1729' }}>
                {issuer.name}
              </Typography>
              <Typography variant="h6" sx={{display:'flex', alignItems:'center', gap:'5px'}}>
                <CheckCircleIcon sx={{ color: "#6DEE84", fontSize:'32px' }} />
                {issuerTitles[id as keyof typeof issuerTitles]}
              </Typography>
            </Stack>
          </Stack>
          
        </CardContent>
      </Card>
    </Stack>
  );
};

export default IssuerHeader;
