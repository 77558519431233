import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Box, Button, MenuItem, TextField } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useAppContext } from "../../Store/AppContext";
import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../Admin/Loading";

const TopBar = ({ accessToken, companyName, isHumb, setHumb }) => {
  const [storeState, dispatch] = useAppContext();
  const [defaultInvestor, setDefaultInvestor] = useState({});
  const [defaultUser, setDefaultUser] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getReceivablesData = async (filter: {
    page: number;
    limit: number;
  }) => {
    const { page, limit } = filter;
    await axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/invest`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: {
          limit,
          page,
        },
      })
      .then(({ data }) => {
        const Data = data.investments.filter(
          (ele) => ele.fundType === "Various funds through NSE"
        );
        // if (Data.length == 0) {
        //   navigate(`/dashboardAdmin/upload-doc`, { state: { status: 0 } });
        // }

        dispatch({ type: "SET_INVESTORS", payload: Data });
        dispatch({ type: "SET_ACTIVE_INVESTORS", payload: Data[0] });
        setDefaultInvestor(Data[0]);
      });
  };

  const HandlerChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setLoading(true);
    setDefaultInvestor(value);
    dispatch({ type: "SET_ACTIVE_INVESTORS", payload: value });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getReceivablesData({ page: 1, limit: 20 });
    setLoading(false);
  }, []);

  if (loading) return <Loading />;
  return (
    <Grid
      container
      justifyContent="space-between"
      className="topbar"
      alignItems="center"
      position="fixed"
      height="60px"
      sx={{ width: { xs: "100vw", md: "82vw" } }}
      display="flex"
      zIndex={10}
    >
      <Grid item xl={2} lg={3} sm={4} xs={6}>
        <Grid container>
          <Grid
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={() => setHumb((prev) => !prev)}
          >
            <Menu
              sx={{ height: "2.3rem", width: "2.5rem", cursor: "pointer" }}
            />
          </Grid>
          {/* <Grid item ml={2} sx={{display:{md:"block",xs:"none"}}}>
            {(location.pathname !== '/dashboardAdmin/investing') && <Button variant="contained" onClick={() => {
              navigate(`/dashboardAdmin/investing`)
            }} >HOME</Button>}
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xl={4} lg={5} sm={4} xs={6}>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
        >
          {/* <Grid item sx={{ marginRight: "20px" }} >
            <Grid item>
              <Grid item >
                <Button variant="contained" sx={{ width: "150px" }} onClick={() => {
                  navigate(`/dashboardAdmin/upload-doc`);
                }} >Upload Docum</Button>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid
            item
            sx={{ marginRight: "20px", display: { md: "block", xs: "none" } }}
          >
            <Grid item>
              {/* <Grid item >
                <Button variant="contained" sx={{ width: "140px" }} onClick={() => {
                  navigate(`/dashboardAdmin/nse/multi/order/${storeState.ACTIVEINVETOR.folio.Folio}`, { state: { status: 0 } });
                }} >Invest In MF</Button>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid
            item
            sx={{ marginRight: "20px", display: { md: "block", xs: "none" } }}
          >
            <Grid item>
              {/* <Grid item>
                <Button
                  variant="contained"
                  sx={{ width: "140px" }}
                  onClick={() => {
                    navigate(
                      `/dashboardAdmin/nse/fd/order/${storeState.ACTIVEINVETOR.folio.Folio}`,
                      { state: { status: 0 } }
                    );
                  }}
                >
                  Invest In FD
                </Button>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid
            item
            sx={{ marginRight: "20px", display: { md: "block", xs: "none" } }}
          >
            <Grid item>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ width: "150px" }}
                  onClick={() => {
                    navigate(`/dashboardAdmin/upload-doc`, {
                      state: { status: 0 },
                    });
                  }}
                >
                  Add Investor
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item alignItems="center">
            <TextField
              name="investor"
              size="small"
              select
              onChange={HandlerChange}
              defaultChecked
              value={defaultInvestor}
            >
              {storeState.INVESTORS.map((ele, indx) => {
                return (
                  <MenuItem key={indx} value={ele}>{`${
                    ele.investor_name ? ele.investor_name : ele?.user_id?.name
                  }`}</MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid
            item
            alignItems="center"
            sx={{ marginLeft: "20px", display: { md: "block", xs: "none" } }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/dashboardAdmin/profile`);
              }}
            >
              <Avatar src="" />
            </Box>
          </Grid>
        </Grid>
        {/* <Grid item>
            <Grid
              container
              alignItems="center"
              style={{ cursor: "pointer" }}
            // onClick={() => props.setSelectedItem("Settings")}
            >
              <Link to='/dashboardAdmin/settings' >
                <img src={settings} alt="settings" />
              </Link>

            </Grid>
          </Grid> */}
      </Grid>
    </Grid>
  );
};

export default TopBar;
