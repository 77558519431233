import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Box,
  Grid
} from "@mui/material";

function Individual_Kyc({ accessToken,setBack }) {
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, SetFormData] = useState<any>({})

  const handleChange = event => {
    let { name, value } = event.target;
    SetFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/checkKYC`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(res => {
        const { data } = res;
        if (!data.succ) {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(data.message);
          return;
        }
        setIsSuccess(true);
        setMessage(data.message);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        setIsFailure(true);
        setMessage(error.message);
        return;
      });
  };
  const heading = "Check KYC";
  const Fields = [
    {
      name: "pan",
      type: "text",
      label: "PAN CARD",
      dropdown: false, required: true,
      value: []
    }
  ]

  return (
    <Grid sx={{ maxWidth: 600, margin: "0 auto" }}>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Typography variant="subtitle1">{heading}</Typography>
          {Fields?.map((ele, idx) => {
            return <div key={idx} >
              <TextField
                label={ele.label}
                onChange={handleChange}
                type={ele.type}
                // value={formData[ele.name]}
                value={formData[ele.name]}
                name={ele.name}
                required={ele.required}
                variant="outlined"
                margin="normal"
                fullWidth
                focused={ele.type == "date"}
                select={ele.dropdown}
                inputProps={{ inputMode: 'text', pattern: '[A-Z]{5}[0-9]{4}[A-Z]{1}' }}
                error={!!validationErrors[ele.name]} // Check if the field has an error
                helperText={validationErrors[ele.name]} /// Display the error message
              >
                {ele.dropdown && ele.value.map((val) =>
                  <MenuItem value={val} >{val}</MenuItem>
                )}
              </TextField>
            </div>
          })}
          <Box sx={{ display: "flex", flex: "row", gap: 4 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2, width: "50%" }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              fullWidth
              onClick={() => setBack("")}
              sx={{ marginTop: 2, width: "50%" }}
            >
              BACK
            </Button>
          </Box>
        </form>
      </CardContent>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={() => setIsFailure(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default Individual_Kyc