import { Grid, Paper, Box, Typography, Button } from '@mui/material'
import React from 'react'
import { FormatNumber } from '../../utils/formatNumber'

function CardsMobileResTrade({
    scheme,
    Profit = "",
    Quantity = "",
    Buy = "",
    Sell = "",
    Entry = "",
    Exit = "",
    Period = ""
}) {

    return (
        <Grid container sm={12}>
            <Paper elevation={2} sx={{ padding: 3, width: "100%", display: "flex", flexDirection: "column", gap: "1rem", borderRadius: 4,backgroundColor:(Period == "SELL"?"#fbf3f3":"")}} >
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                    <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
                        {scheme}
                    </Typography>
                    <Box>
                        <Typography sx={{ fontSize: "1rem", fontWeight: "650" }}>
                            {"₹" + FormatNumber(Profit)}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
                            {Quantity}
                        </Typography>
                    </Box>
                    {Buy !== "" && <Box sx={{ backgroundColor: "#b1eeb9", color: "#01651c", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {"₹" + FormatNumber(Buy)}
                        </Typography>
                    </Box>}
                    <Box sx={{ backgroundColor: "red", color: "white", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {"₹" + FormatNumber(Sell)}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ backgroundColor: "#b1eeb9", color: "#01651c", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {Entry}
                        </Typography>
                    </Box>
                    {Exit !== "" && <Box sx={{ backgroundColor: "red", color: "white", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {Exit}
                        </Typography>
                    </Box>}
                    {Period == "SELL" ? <Box sx={{ backgroundColor: "red", color: "white", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {Period}
                        </Typography>
                    </Box> : <Box sx={{ backgroundColor: "#318ad6", color: "white", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {Period}
                        </Typography>
                    </Box>}
                </Box>
            </Paper>
        </Grid>
    )
}

export default CardsMobileResTrade