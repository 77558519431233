import { Grid, Paper, Box, Typography, Button } from '@mui/material'
import React from 'react'
import { FormatNumber } from '../../utils/formatNumber'

function CardsMobileRes({
    scheme,
    Amount,
    date,
    TranType,
    TranStatus,
    isBtn = false,
    setFormData,
    setIsModel,
    Data={},
}) {

    const continueHandler = ()=>{
        setFormData(Data);
        setIsModel(true);
    }
    return (
        <Grid container sm={12}>
            <Paper elevation={2} sx={{ padding: 3, width: "100%", display: "flex", flexDirection: "column", gap: "1rem", borderRadius: 4,backgroundColor:(TranType.toLocaleLowerCase().includes("redemption")?"#fbf3f3":"") }} >
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                    <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
                        {scheme}
                    </Typography>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "650" }}>
                        {"₹" + FormatNumber(Amount)}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                        <Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}>
                            {date}
                        </Typography>
                    </Box>
                    {TranType.toLocaleLowerCase().includes("purchase") ? <Box sx={{ backgroundColor: "#b1eeb9", color: "#01651c", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {TranType?.split(" ")[1]}
                        </Typography>
                    </Box> : <Box sx={{ backgroundColor: "#318ad6", color: "white", padding: 1, borderRadius: 2 }}>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                            {TranType}
                        </Typography>
                    </Box>}
                    {isBtn ? <>
                        <Button variant='contained' onClick={continueHandler} > Continue </Button>
                    </> : <>
                        {TranStatus.toLocaleLowerCase().includes("reject") ?
                            <Box sx={{ backgroundColor: "red", color: "white", padding: 1, borderRadius: 2 }}>
                                <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                                    {TranStatus?.split("/")[0]}
                                </Typography>
                            </Box> : <Box sx={{ backgroundColor: "#318ad6", color: "white", padding: 1, borderRadius: 2 }}>
                                <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                                    {TranStatus}
                                </Typography>
                            </Box>
                        }</>}
                </Box>
            </Paper>
        </Grid>
    )
}

export default CardsMobileRes