import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default function Datefilter({name,label,value,changeHandler,inputFormat,maxDate,minDate}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} sx={{height:"2.5rem",marginRight:"1rem"}}>
        <DesktopDatePicker
          label={label}
          inputFormat={inputFormat}
          value={value}
          openTo="day"
          views={['year', 'month', 'day']}
          onChange={(e)=>changeHandler(name,e)}
          renderInput={(params) => <TextField size='small' name={name}  {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}
