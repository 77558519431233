import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useParams,useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
  Stack,
  Paper,
  Divider,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface PurchaseDetailsState {
  amount: number;
  interestYield:number;
  tenure:number;
  interestPayoutFrequency:string;
}
type ApiResponse = {
  id: string;
  status?: string;
  message?: string;
  success?: boolean;
};

const steps = ["Personal Info", "Nominee Details", "Bank Details"];

const PurchaseDetails = () => {
  const { schemeId } = useParams<{ schemeId: string }>();
  const location = useLocation();
  const state = location.state as PurchaseDetailsState;
  const amount = state?.amount || 0;
  const tenure = state?.tenure || 0;
  const interestYield = state?.interestYield || 0; 
  const interestPayoutFrequency = state?.interestPayoutFrequency || 0; 
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    salutation: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    mobile: "",
    pan: "",
    aadhaar: "",
    maritalStatus: "",
    annualIncome: "",
    occupation: "",
    birthPlace: "",
    birthCountry: "",
    nationality: "",
    residentialAddress: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
    bank: {
      accountType: "",
      ifsc: "",
      accountNumber: "",
    },
    nominees: {
      salutation: "",
      firstName: "",
      middleName: "",
      lastName: "",
      relation: "",
      dateOfBirth: "",
      guardian: {
        salutation: "",
        firstName: "",
        middleName: "",
        lastName: "",
      },
    },
  });

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const validateForm = () => {
    switch (currentStep) {
      case 0:
        return (
          formData.salutation &&
          formData.firstName &&
          formData.lastName &&
          formData.dateOfBirth &&
          formData.gender &&
          formData.residentialAddress.addressLine1 &&
          formData.residentialAddress.city &&
          formData.residentialAddress.state &&
          formData.residentialAddress.pincode &&
          formData.residentialAddress.country
        );
      case 1:
        return (
          formData.nominees.salutation &&
          formData.nominees.firstName &&
          formData.nominees.middleName &&
          formData.nominees.lastName &&
          formData.nominees.relation &&
          formData.nominees.dateOfBirth
        );
      case 2:
        return (
          formData.bank.accountType &&
          formData.bank.ifsc &&
          formData.bank.accountNumber
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    setIsNextDisabled(!validateForm());
  }, [formData, currentStep]);


  const handleNavigation = () => {
    if (apiResponse) {
      
      navigate('/CreateFdPayment', { state: { apiResponse } });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "ifsc" || name === "accountNumber" || name === "accountType") {
      setFormData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  console.log(interestPayoutFrequency); 

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;

    if (name === "accountType") {
      setFormData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      residentialAddress: { ...formData.residentialAddress, [name]: value },
    });
  };

  const handleNomineesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      nominees: { ...formData.nominees, [name]: value },
    });
  };

  const handleNomineesSelect = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      nominees: { ...formData.nominees, [name!]: value },
    });
  };

  const handleNomineesGuardianChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      nominees: {
        ...prevData.nominees,
        guardian: {
          ...prevData.nominees.guardian,
          [name]: value,
        },
      },
    }));
  };

  const handleNomineesGuardianSelect = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      nominees: {
        ...prevData.nominees,
        guardian: {
          ...prevData.nominees.guardian,
          [name!]: value,
        },
      },
    }));
  };

  const handleBankChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      bank: { ...formData.bank, [name!]: value },
    });
  };

  const isUnder18 = (dateOfBirth?: string): boolean => {
    const today = new Date();
    const dob = new Date(dateOfBirth || "");
    const age =
      today.getFullYear() -
      dob.getFullYear() -
      (today.getMonth() < dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
        ? 1
        : 0);

    return age < 18;
  };

  const isNomineeUnder18 = isUnder18(formData.nominees?.dateOfBirth);

  console.log(tenure);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const dataToSend = {
        scheme_id: schemeId,
        tenure: 18,
        amount: amount,
        payable_to: "first_holder",
        applicants: [
          {
            salutation: formData.salutation.toLowerCase(),
            first_name: formData.firstName,
            middle_name: formData.middleName,
            last_name: formData.lastName,
            date_of_birth: formData.dateOfBirth,
            gender: formData.gender.toLowerCase(),
            email: formData.email,
            mobile: formData.mobile,
            pan: formData.pan,
            aadhaar: formData.aadhaar,
            marital_status: formData.maritalStatus,
            annual_income: formData.annualIncome,
            occupation: formData.occupation,
            birth_place: formData.birthPlace,
            birth_country: formData.birthCountry,
            nationality: formData.nationality,
            spouse: {
              salutation: "mr",
              first_name: "Devidas",
              middle_name: "",
              last_name: "kawale",
            },
            residential_address: {
              address_line_1: formData.residentialAddress.addressLine1,
              address_line_2: formData.residentialAddress.addressLine2,
              city: formData.residentialAddress.city,
              state: formData.residentialAddress.state,
              pincode: formData.residentialAddress.pincode,
              country: formData.residentialAddress.country,
            },
            permanent_address_same_as_residential: true,
          },
        ],
        bank: {
          account_type: formData.bank.accountType.toLowerCase(),
          ifsc: formData.bank.ifsc,
          account_number: formData.bank.accountNumber,
        },
        nominees: [
          {
            salutation: formData.nominees.salutation,
            first_name: formData.nominees.firstName,
            middle_name: formData.nominees.middleName,
            last_name: formData.nominees.lastName,
            relation: formData.nominees.relation,
            date_of_birth: formData.nominees.dateOfBirth,
            guardian: {
              salutation: formData.nominees.guardian.salutation,
              first_name: formData.nominees.guardian.firstName,
              middle_name: formData.nominees.guardian.middleName,
              last_name: formData.nominees.guardian.lastName,
            },
          },
        ],
        ip_address: "152.58.16.84",
      };

      // Make the API call
      const response = await axios.post(
        "http://localhost:8081/v1/user/fixeddeposit/createapplication",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("tokens") || "{}").accessToken
            }`,
          },
        }
      );

      console.log(response.statusText);

      const { data } = response;

      setApiResponse(data);

      if (!data.success) {
        // setIsLoading(false);
        // setIsFailure(true);
        // setMessage(data.message);
        return;
      }

      //   setIsLoading(false);
      // //   setIsSuccess(true);
      // //   setMessage(data.message);
      //   setTimeout(() => {
      //     handleNext(); // Proceed to the next step or action
      //   }, 2000);
    } catch (error) {
      console.error("Error submitting application:", error);
      //   setIsLoading(false);
      //   setIsFailure(true);
      //   setMessage(error?.response?.data?.message || "An error occurred");
    }
  };

  console.log(apiResponse?.id);

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Salutation</InputLabel>
                <Select
                  name="salutation"
                  value={formData.salutation || ""}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="mr">mr</MenuItem>
                  <MenuItem value="ms">mrs</MenuItem>
                  <MenuItem value="mrs">ms</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.firstName}
                helperText={!formData.firstName ? "First name is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Middle Name"
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.lastName}
                helperText={!formData.lastName ? "Last name is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Date of Birth"
                name="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                error={!formData.dateOfBirth}
                helperText={
                  !formData.dateOfBirth ? "Date of Birth is required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={formData.gender || ""}
                  onChange={handleSelectChange}
                  required
                  error={!formData.gender}
                >
                  <MenuItem value="Male">male</MenuItem>
                  <MenuItem value="Female">female</MenuItem>
                  <MenuItem value="Other">other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                required
                type="email"
                error={!formData.email}
                helperText={!formData.email ? "Email is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                fullWidth
                required
                type="tel"
                error={!formData.mobile}
                helperText={!formData.mobile ? "Mobile number is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="PAN"
                name="pan"
                value={formData.pan}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.pan}
                helperText={!formData.pan ? "PAN is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Aadhaar"
                name="aadhaar"
                value={formData.aadhaar}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.aadhaar}
                helperText={
                  !formData.aadhaar ? "Aadhaar number is required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Marital Status</InputLabel>
                <Select
                  name="maritalStatus"
                  value={formData.maritalStatus || ""}
                  onChange={handleSelectChange}
                  required
                  error={!formData.maritalStatus}
                >
                  <MenuItem value="married">married</MenuItem>
                  <MenuItem value="unmarried">unmarried</MenuItem>
                  <MenuItem value="other">other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Annual Income</InputLabel>
                <Select
                  name="annualIncome"
                  value={formData.annualIncome || ""}
                  onChange={handleSelectChange}
                  required
                  error={!formData.annualIncome}
                >
                  <MenuItem value="upto_5_lakh">upto 5 lakh</MenuItem>
                  <MenuItem value="above_5_lakh_upto_10_lakh">
                    above 5 lakh upto 10 lakh
                  </MenuItem>
                  <MenuItem value="above_10_lakh_upto_15_lakh">
                    above 10 Lakh upto 15 lakh
                  </MenuItem>
                  <MenuItem value="above_10_lakh_upto_25_lakh">
                    above 10 lakh upto 25 lakh
                  </MenuItem>
                  <MenuItem value="above_15_lakh_upto_25_lakh">
                    above 15 lakh upto 25 lakh
                  </MenuItem>
                  <MenuItem value="above_25_lakh_upto_50_lakh">
                    above 25 Lakh upto 50 lakh
                  </MenuItem>
                  <MenuItem value="above_50_lakh_upto_1_crore">
                    above 50 Lakh upto 1 crore
                  </MenuItem>
                  <MenuItem value="above_1_crore">above 1 crore</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Occupation</InputLabel>
                <Select
                  name="occupation"
                  value={formData.occupation || ""}
                  onChange={handleSelectChange}
                  required
                  error={!formData.occupation}
                >
                  <MenuItem value="business">business</MenuItem>
                  <MenuItem value="agriculture">agriculture</MenuItem>
                  <MenuItem value="forex_dealer">forex dealer</MenuItem>
                  <MenuItem value="service_public_sector">
                    service public sector
                  </MenuItem>
                  <MenuItem value="service_private_sector">
                    service private sector
                  </MenuItem>
                  <MenuItem value="service_government_sector">
                    service government sector
                  </MenuItem>
                  <MenuItem value="others_professional">
                    others professional
                  </MenuItem>
                  <MenuItem value="others_self_employed">
                    others self employed
                  </MenuItem>
                  <MenuItem value="others_retired">others retired</MenuItem>
                  <MenuItem value="others_housewife">others housewife</MenuItem>
                  <MenuItem value="others_student">others student</MenuItem>
                  <MenuItem value="others">others</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
                  <TextField
                    label="Occupation"
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={!formData.occupation}
                    helperText={!formData.occupation ? "Occupation is required" : ""}
                  />
                </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Birth Place"
                name="birthPlace"
                value={formData.birthPlace}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.birthPlace}
                helperText={
                  !formData.birthPlace ? "Birth Place is required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Birth Country"
                name="birthCountry"
                value={formData.birthCountry}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.birthCountry}
                helperText={
                  !formData.birthCountry ? "Birth Country is required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nationality"
                name="nationality"
                value={formData.nationality}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.nationality}
                helperText={
                  !formData.nationality ? "Nationality is required" : ""
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Address Line 1"
                name="addressLine1"
                value={formData.residentialAddress.addressLine1}
                onChange={handleAddressChange}
                fullWidth
                required
                error={!formData.residentialAddress.addressLine1}
                helperText={
                  !formData.residentialAddress.addressLine1
                    ? "Address Line 1 is required"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address Line 2"
                name="addressLine2"
                value={formData.residentialAddress.addressLine2}
                onChange={handleAddressChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="city"
                value={formData.residentialAddress.city}
                onChange={handleAddressChange}
                fullWidth
                required
                error={!formData.residentialAddress.city}
                helperText={
                  !formData.residentialAddress.city ? "City is required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                name="state"
                value={formData.residentialAddress.state}
                onChange={handleAddressChange}
                fullWidth
                required
                error={!formData.residentialAddress.state}
                helperText={
                  !formData.residentialAddress.state ? "State is required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pincode"
                name="pincode"
                value={formData.residentialAddress.pincode}
                onChange={handleAddressChange}
                fullWidth
                required
                error={!formData.residentialAddress.pincode}
                helperText={
                  !formData.residentialAddress.pincode
                    ? "Pincode is required"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                name="country"
                value={formData.residentialAddress.country}
                onChange={handleAddressChange}
                fullWidth
                required
                error={!formData.residentialAddress.country}
                helperText={
                  !formData.residentialAddress.country
                    ? "Country is required"
                    : ""
                }
              />
            </Grid>
          </Grid>
        );

      case 1:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Salutation</InputLabel>
                  <Select
                    name="salutation"
                    value={formData.nominees.salutation || ""}
                    onChange={handleNomineesSelect}
                  >
                    <MenuItem value="mr">mr</MenuItem>
                    <MenuItem value="ms">mrs</MenuItem>
                    <MenuItem value="mrs">ms</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={formData.nominees.firstName}
                  onChange={handleNomineesChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Middle Name"
                  name="middleName"
                  value={formData.nominees.middleName}
                  onChange={handleNomineesChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={formData.nominees.lastName}
                  onChange={handleNomineesChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Relation</InputLabel>
                  <Select
                    name="relation"
                    value={formData.nominees.relation || ""}
                    onChange={handleNomineesSelect}
                  >
                    <MenuItem value="brother">brother</MenuItem>
                    <MenuItem value="sister">sister</MenuItem>
                    <MenuItem value="father">father</MenuItem>
                    <MenuItem value="mother">mother</MenuItem>
                    <MenuItem value="son">son</MenuItem>
                    <MenuItem value="daughter">daughter</MenuItem>
                    <MenuItem value="husband">husband</MenuItem>
                    <MenuItem value="wife">wife</MenuItem>
                    <MenuItem value="other">other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Date of Birth"
                  name="dateOfBirth"
                  type="date"
                  value={formData.nominees.dateOfBirth}
                  onChange={handleNomineesChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                  error={!formData.nominees.dateOfBirth}
                  helperText={
                    !formData.dateOfBirth ? "Date of Birth is required" : ""
                  }
                />
              </Grid>
            </Grid>
            {isNomineeUnder18 && (
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ paddingLeft: "20px", paddingBlock: "20px" }}
                >
                  Guardian Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Salutation</InputLabel>
                      <Select
                        name="salutation"
                        value={formData.nominees.guardian.salutation || ""}
                        onChange={handleNomineesGuardianSelect}
                      >
                        <MenuItem value="mr">mr</MenuItem>
                        <MenuItem value="ms">mrs</MenuItem>
                        <MenuItem value="mrs">ms</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={formData.nominees.guardian.firstName}
                      onChange={handleNomineesGuardianChange}
                      fullWidth
                      required
                      error={!formData.nominees.guardian.firstName}
                      helperText={
                        !formData.firstName ? "Guardian name is required" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Middle Name"
                      name="middleName"
                      value={formData.nominees.guardian.middleName}
                      onChange={handleNomineesGuardianChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={formData.nominees.guardian.lastName}
                      onChange={handleNomineesGuardianChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}
          </>
        );

      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Account Type</InputLabel>
                <Select
                  name="accountType"
                  value={formData.bank.accountType || ""}
                  onChange={handleBankChange}
                  required
                  error={!formData.bank.accountType}
                >
                  <MenuItem value="Savings">Savings</MenuItem>
                  <MenuItem value="Current">Current</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="IFSC"
                name="ifsc"
                value={formData.bank.ifsc || ""}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.bank.ifsc}
                helperText={!formData.bank.ifsc ? "IFSC is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Account Number"
                name="accountNumber"
                value={formData.bank.accountNumber || ""}
                onChange={handleInputChange}
                fullWidth
                required
                error={!formData.bank.accountNumber}
                helperText={
                  !formData.bank.accountNumber
                    ? "Account Number is required"
                    : ""
                }
              />
            </Grid>
          </Grid>
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 900,
        margin: "auto",
        padding: "2rem",
        backgroundColor: "white",
        borderRadius: "8px",
      }}
    >
      {/* <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", fontSize: "26px", color: "#0E1729" }}
        >
          Investment Amount
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "26px",
            color: "#22577a",
            backgroundColor: "#c7f9cc",
            borderRadius: "10px",
            paddingInline: "10px",
          }}
        >
          ₹ {amount || "0"}
        </Typography>
      </Stack> */}
      {apiResponse?.status !== "awaiting_payment" ? (
        <>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box mt={3} mb={2}>
            {renderStepContent()}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              disabled={currentStep === 0}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
            {currentStep === steps.length - 1 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isNextDisabled}
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCurrentStep(currentStep + 1)}
                disabled={isNextDisabled}
              >
                Next
              </Button>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // height: '100vh',
              padding: "20px",
              bgcolor: "#fff",
              boxShadow: "2",
              borderRadius: "10px",
            }}
          >
            {/* Success Icon and Message */}
            <CheckCircleIcon sx={{ fontSize: 60, color: "#29D9AA" }} />
            <Typography variant="h3" fontWeight="400" sx={{ my: 2, textAlign:'center' }}>
              Your application submitted successfully
            </Typography>


            {/* Additional Message */}
            <Grid sx={{display:'flex',flexDirection:'column',gap:'20px', bgcolor:'transparent', color:'#0a1128', boxShadow:'0',mt:'30px', padding:'10px', paddingBottom:'20px', borderRadius:'10px',width:'100%'}}>
              <Typography variant="h4" sx={{textAlign:'center', fontWeight:'400', color:'#0a1128'}}>Investment Details</Typography>
            <Divider sx={{width:'100%',height:'4px', marginBlock:'10px', color:'black'}}/>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent:'space-between',
                  paddingInline:'20px',
                  gap: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "20px", fontWeight: "400" }}
                >
                  Investment amount:
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                  ₹ {amount || "0"}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent:'space-between',
                  paddingInline:'20px',
                  gap: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "20px", fontWeight: "400" }}
                >
                  Interest Rate:
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                   {interestYield || "0"}%
                </Typography>
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent:'space-between',
                  paddingInline:'20px',
                  gap: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "20px", fontWeight: "400" }}
                >
                  Tenure:
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                   {tenure || ""}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent:'space-between',
                  paddingInline:'20px',
                  gap: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "20px", fontWeight: "400" }}
                >
                  Payout frequency:
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                   {interestPayoutFrequency || ""}
                </Typography>
              </Stack>

            </Grid>
            {/* Back Button */}
            <Button
              variant="outlined"
              sx={{ mt: 4, border:'1px solid #29d9aa', color:'#29d9aa', borderRadius:'20px', px: 5, fontWeight: "bold" }}
              size="large"
              onClick={() =>
                navigate(`/dashboardAdmin/createfdpayment/${apiResponse?.id}`)
              }
            >
              proceed to payment
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PurchaseDetails;
