import * as React from "react";
import { Box, TextField, MenuItem, Grid, Paper } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Individual_eKycReg from "./Individual_eKycReg"
import Individual_Kyc from "./Individual_Kyc";

function IINstatus({ user, accessToken }) {
    const [activeStep, setActiveStep] = React.useState(-1);
    const [stepperName, setStepperName] = React.useState<any>([]);
    const [isKYC, setKYC] = React.useState<any>("nKYC");

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const changeHandler = (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (name == "pan") {

        } else {
            setKYC(value);
        }
    }

    const ITEMS = [{ code: "cKYC", name: "KYC Check" }, { code: "nKYC", name: "KYC Registration" }];
    return (
        <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "70vh" }} >
            <Grid item sx={{ width: { xs: "100vw", sm: "50vw" } }} >
                <Paper elevation={2} sx={{ padding: 5 }}>
                    {isKYC == "" && <> <Typography variant="h6" gutterBottom sx={{ width: "100%", textAlign: "center" }}>
                        Choose KYC next Step
                    </Typography>
                        <TextField
                            name="kyc"
                            onChange={changeHandler}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select
                            required
                        >
                            {
                                ITEMS.map((ele, idx) => {
                                    return <MenuItem key={idx} value={ele.code} >{ele.name}</MenuItem>
                                })
                            }
                        </TextField> </>}
                    {isKYC == "cKYC" && <Individual_Kyc setBack={setKYC} accessToken={accessToken} />}
                    {isKYC == "nKYC" && <Individual_eKycReg setBack={setKYC} accessToken={accessToken} />}
                </Paper>
            </Grid>
        </Grid>
    )
}

export default IINstatus