import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Box
} from "@mui/material";

function Individual_nomniee({ accessToken, handleBack, handleNext, user, capturedData, setCapturedData}) {
    const [validationErrors, setValidationErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [message, setMessage] = useState("");
    const [formData, SetFormData] = useState<any>({...capturedData,...capturedData.investorType_details,nominee1_type:"Major"})
  
    const handleChange = event => {
      let { name, value } = event.target;
      SetFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (event: any) => {
      event.preventDefault();
      setCapturedData((prev)=>({
        ...prev,...formData
      }))
      console.log("first",{...capturedData,...formData});
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/nomdetails`,
          { ...formData },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(res => {
          const { data } = res;
          if (!data.succ) {
            setIsLoading(false);
            setIsFailure(true);
            setMessage(data.message);
            return;
          }
          setIsSuccess(true);
          setMessage(data.message);
          setIsLoading(false);
          setCapturedData((prev) => ({
            ...prev,
            ["CustomerID"]: data.IIN
          }))
          setTimeout(() => {
            handleNext();
          }, 2000);
        })
        .catch(error => {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(error.message);
          return;
        });
    };
    const heading = "Nominee details";
    const Fields = [
        {
            name: "nominee1_type",
            type: "text",
            label: "Nominee Type",
            dropdown: false, 
            required: true, 
            isFreezed:true,
            value:[]
        },
        {
            name: "nominee1_name",
            type: "text",
            label: "Nominee Name",
            dropdown: false, required: true,
            value:[]
        },
        {
            name: "nominee1_relation",
            type: "text",
            label: "Nominee Relation",
            dropdown: true, required: true,
            value:["Father", "Mother", "Wife", "Husband", "Son", "Daughter","Father-in-law", "Mother-in-law","Son-in-law" ,
                  "Daughter-in-law","Aunt" ,"Uncle", "Niece","Nephew", "Brother", "Sister","Grand Father","Grand Mother","Others"]
        },
    ]

  return (
    <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Typography variant="subtitle1">{heading}</Typography>
          {Fields?.map((ele, idx) => {
            return <div key={idx} >
              <TextField
                label={ele.label}
                onChange={handleChange}
                type={ele.type}
                // value={formData[ele.name]}
                value={formData[ele.name]}
                name={ele.name}
                required={ele.required}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={ele.isFreezed}
                focused={ele.type == "date"}
                select={ele.dropdown}
                error={!!validationErrors[ele.name]} // Check if the field has an error
                helperText={validationErrors[ele.name]} /// Display the error message
              >
                {ele.dropdown &&ele.value.map((val) =>
                  <MenuItem value={val} >{val}</MenuItem>
                )}
              </TextField>
            </div>
          })}
          <Box sx={{display:"flex",flex:"row",gap:4}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {
                "Back"
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </form>
      </CardContent>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={() => setIsFailure(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>
    </Card>
  )
}


export default Individual_nomniee