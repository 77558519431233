import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Sigup from './Sigup';
import Password from './Password';
import VerifyEmail from './VerifyEmail';
import { Grid, Typography } from '@mui/material';
import loginImage from "../../../images/login-image.png";

const steps = [
  'SignUp',
  'Password',
];

export default function SignupStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setformData] = React.useState({
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    otp: "",
    confirmPassword: ""
  })
  const nextHandler = () => {
    setActiveStep((prev) => prev + 1);
  }
  return (
    <Grid  container sx={{height:"100vh",display:"flex",flexDirection:"row",justifyContent:"center" ,  backgroundColor:"#3599cc", }}>
      
      <Grid item md={6} sx={{ display:"flex",flexDirection:"column",gap:"2vh"}}>
      <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            p:2
          }}
        >
          <Typography
            sx={{
              fontFamily: "logoFont",
              fontSize: "2rem",
              fontWeight: "500",
            }}
          >
            <span style={{ fontFamily: "logoFont",color:"white" }}>KLARFIN</span>
          </Typography>
        </Box>
        <Stepper activeStep={activeStep} alternativeLabel sx={{color:"white"}}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel >{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep == 0 && <Sigup nextHandler={nextHandler} formData={formData} setformData={setformData} />}
        {activeStep == 1 && <Password nextHandler={nextHandler} formData={formData} setformData={setformData} />}
        {/* {activeStep == 2 && <VerifyEmail nextHandler={nextHandler} formData={formData} setformData={setformData} />} */}
      </Grid>
      <Grid
        item
        sm={6}
        // className="login-right"
        sx={{
          display: { md: "block", sm: "none", xs: "none" },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xl={8.5} lg={10.5} md={12}>
            <img
              src={loginImage}
              alt="Manage cashflows and forecase cashflows efficiently"
              className="login-image"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}