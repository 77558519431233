import React, { useState } from 'react'
import { validateEmail, validatePassword } from "../../../utils/validators";
import { Box, CardContent, TextField, Card, Button, MenuItem, CircularProgress, Typography, Alert, Snackbar } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const Sigup = ({ nextHandler, formData, setformData }) => {
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false);
  const [Msg, setMsg] = useState("");
  const [validationErrors, setValidationErrors] = useState<any>({});
  const navigate = useNavigate();

  const changeHandler = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name == "email") {
      if (!validateEmail(formData.email)) {
        setValidationErrors((prev) => ({
          ...prev,
          [name]: true
        }))
        if (value === "") {
          setValidationErrors((prev) => ({
            ...prev,
            [name]: false
          }))
        }
      }
    }
    setformData((formData) => ({
      ...formData,
      [name]: value
    }))
  }

  const stepHandler = () => {
    if (formData.name == "" || formData.email == "" || formData.mobileNumber == "") {
      setError(true);
      setMsg("Please, Fill the required inputs!!!");
      return;
    } else {
      setLoading(true)
      setTimeout(() => {
        nextHandler();
        setLoading(false)
      }, 1000);
    }
  }

  return (
    <Box sx={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
      <Card sx={{width:{md:"42vw",sm:"75vw",xs:"97vw"}, p: 1}}>
        <Snackbar open={isError} autoHideDuration={5000} onClose={() => setError(false)} >
          <Alert severity='error' >{Msg}</Alert>
        </Snackbar>
        <CardContent  >
          <form onSubmit={nextHandler} style={{ width: '100%',display:"flex",flexDirection:"column",gap:2 }}>
            <Box  sx={{ display: "flex", flexDirection:{sm:"row",xs:"column"}, width: "100%",alignItems:{md: "center",sm:"center",xs:"" },justifyContent:"center"}} >
              <Typography sx={{ width:{xs:"100%",sm :"30%"}, fontSize: "1.1rem", fontWeight: "600" }}> Name (As Per Pancard) </Typography>
              <TextField
                name="name"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={changeHandler}
                error={!!validationErrors.Scheme}
                helperText={validationErrors.Scheme}
                required
                value={formData.name}
              >
              </TextField>
            </Box>
            <Box  sx={{ display: "flex", flexDirection:{sm:"row",xs:"column"},width: "100%",mt:2 , alignItems:{md: "center",sm:"",xs:"" }}} >
              <Typography sx={{ width:{xs:"100%",sm :"30%"}, mb:1,fontSize: "1.1rem", fontWeight: "600" }}>Email </Typography>
              <TextField
                placeholder="example@gmail.com"
                fullWidth
                name="email"
                value={formData.email}
                onChange={changeHandler}
                error={validationErrors.email && !validateEmail(formData.email)}
                helperText={
                  validationErrors.email && !validateEmail(formData.email)
                    ? "Invalid Email"
                    : ""
                }
                required
              />
            </Box>
            <Box  sx={{ display: "flex", flexDirection:{sm:"row",xs:"column"}, mt:2 ,width: "100%", alignItems:{md: "center",sm:"",xs:"" }}}  >
              <Typography sx={{ width:{xs:"100%",sm :"30%"}, fontSize: "1.1rem", fontWeight: "600" }}> Mobile </Typography>
              <TextField
                name="mobileNumber"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={changeHandler}
                error={!!validationErrors.Scheme}
                helperText={validationErrors.Scheme}
                required
                value={formData.mobile}
              >
              </TextField>
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={stepHandler}
                sx={{ marginTop: 2, marginLeft: "35%", width: "30%" }}
                disabled={isLoading}
              >
                {isLoading && <CircularProgress style={{ width: "20px", height: "20px", marginRight: "10px", color: "white" }} />} {'SUBMIT'}
              </Button >
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center",marginTop:"10px" }}>
              <Typography >Already have an account? <a onClick={() => navigate('/')} style={{ color: "#1876d1", textDecoration: "underline", cursor: "pointer" }} >Sign In</a> </Typography>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box >
  )
}
export default Sigup