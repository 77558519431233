import React, { useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    CircularProgress,
    Snackbar,
    Alert,
    Card,
    CardContent,
    Typography,
    MenuItem,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Radio,
    Box
} from "@mui/material";
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { dateConverter } from "../../../../../utils/formatNumber";
import { STATE } from "../../../../../Data/data";

function Individual_details({ accessToken, handleBack, handleNext, user, capturedData, setCapturedData }) {
    const [validationErrors, setValidationErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [message, setMessage] = useState("");
    const [formData, SetFormData] = useState<any>({ ...capturedData, ...capturedData.investorType_details })
    
    const handleChange = event => {
        let { name, value } = event.target

        SetFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

    };

    const handleChangeDOB = (name,value)=>{
        let val = dateConverter(value);
        SetFormData((prev)=>({
            ...prev,
            [name]:val
        }))
    }

    const handleSubmit = async (event: any) => {
        setCapturedData({...formData});
        event.preventDefault();
        setIsLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/condetails`,
                { ...formData, investorType: capturedData.investorType },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then(res => {
                const { data } = res;
                if (!data.succ) {
                    setIsLoading(false);
                    setIsFailure(true);
                    setMessage(data.message);
                    return;
                }
                setCapturedData((prev) => ({
                    ...prev,
                    ["doc_id"]: data.id
                }))
                setIsSuccess(true);
                setMessage(data.message);
                setIsLoading(false);
                setTimeout(() => {
                    handleNext();
                }, 2000);
            })
            .catch(error => {
                setIsLoading(false);
                setIsFailure(true);
                setMessage(error.message);
                return;
            });
    };
    const heading = "Contact Details";
    const Fields = [
        {
            name: "inv_name",
            type: "text",
            label: "Name (Should be as per PAN)",
            dropdown: false, required: true,
            value: []
        },
        {
            name: "pan",
            type: "text",
            label: "PAN",
            dropdown: false, required: true,
            value: []
        },
        {
            name: "addr1",
            type: "text",
            label: "Address",
            dropdown: false, required: true,
            value: []
        },
        {
            name: "city",
            type: "text",
            label: "CITY",
            dropdown: false, required: true,
            value: []
        },
        {
            name: "state",
            type: "text",
            label: "State",
            dropdown: true, required: true,
            value: STATE
        },
        {
            name: "pincode",
            type: "phone",
            label: "Pincode",
            dropdown: false, required: true,
            value: []
        },
        {
            name: "mobile_no",
            type: "phone",
            label: "Mobile Number",
            dropdown: false, required: true,
            value: []
        },
        {
            name: "mobile_relation",
            type: "text",
            label: "Mobile Relation",
            dropdown: true, required: true,
            value: [
                { code: "SE", value: "Self" },
                { code: "SP", value: "Spouse" },
                { code: "DC", value: "Dependent Children" },
                { code: "DS", value: "Dependent Siblings" },
                { code: "DP", value: "Dependent Parents" },
                { code: "GD", value: "Guardian" },
                { code: "PM", value: "PMS" },
                { code: "CD", value: "Custodian" },
                { code: "PO", value: "POA" }
            ]
        },
        {
            name: "email",
            type: "email",
            label: "Email",
            dropdown: false, required: true,
            value: []
        },
        {
            name: "email_relation",
            type: "text",
            label: "Email Relation",
            dropdown: true, required: true,
            value: [
                { code: "SE", value: "Self" },
                { code: "SP", value: "Spouse" },
                { code: "DC", value: "Dependent Children" },
                { code: "DS", value: "Dependent Siblings" },
                { code: "DP", value: "Dependent Parents" },
                { code: "GD", value: "Guardian" },
                { code: "PM", value: "PMS" },
                { code: "CD", value: "Custodian" },
                { code: "PO", value: "POA" }
            ]
        },
    ]

    return (
        <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
            <CardContent>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <Typography variant="subtitle1">{heading}</Typography>
                    {Fields?.map((ele, idx) => {
                        return <div key={idx} >
                            {ele.type == "date" ? <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack mt={2} mb={1}>
                                    <DesktopDatePicker
                                        label={ele.label}
                                        inputFormat={"dd-MMM-yyyy"}
                                        maxDate={dateConverter(new Date().getTime())}
                                        value={formData[ele.name]}
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        onChange={(val) => handleChangeDOB(ele.name,val)}
                                        renderInput={(params) => <TextField fullWidth name={ele.name} onChange={handleChange} value={formData[ele.name]} {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider> :
                                <TextField
                                    label={ele.label}
                                    onChange={handleChange}
                                    type={ele.type}
                                    value={formData[ele.name]}
                                    name={ele.name}
                                    //required={ele.required}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    focused={ele.type == "date"}
                                    select={ele.dropdown}
                                    error={!!validationErrors[ele.name]} // Check if the field has an error
                                    helperText={validationErrors[ele.name]} /// Display the error message
                                >
                                    {ele.dropdown && ele.value.map((val) =>
                                        <MenuItem value={val.code} >{val.value}</MenuItem>
                                    )}
                                </TextField>}
                        </div>
                    })}
                    <Box sx={{ display: "flex", flex: "row", gap: 4 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleBack}
                            disabled={isLoading}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            {"Back"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </Box>
                </form>
            </CardContent>
            <Snackbar
                open={isSuccess}
                autoHideDuration={3000}
                onClose={() => setIsSuccess(false)}
                sx={{ marginBottom: 2 }}
            >
                <Alert severity="success" sx={{ width: "100%" }} className="snack">
                    {message}
                </Alert>
            </Snackbar>

            <Snackbar
                open={isFailure}
                autoHideDuration={3000}
                onClose={() => setIsFailure(false)}
                sx={{ marginBottom: 2 }}
            >
                <Alert severity="error" sx={{ width: "100%" }} className="snack">
                    {message}
                </Alert>
            </Snackbar>
        </Card>
    )
}



export default Individual_details