import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box
} from "@mui/material";
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { dateConverter } from "../../../../utils/formatNumber";

function DirectorsDetails({ accessToken, handleBack, setCapturedData, heading, Fields, handleNext, user, capturedData }) {
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [pancard, setPancard] = useState<any>(null);
  const [aadharcard, setAadharcard] = useState<any>(null);
  const [message, setMessage] = useState("");
  const dateConverterRever = (str) => {
    if (str) {
      const month = { "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04", "May": "05", "Jun": "06", "Jul": "07", "Aug": "08", "Sep": "09", "Oct": "10", "Nov": "11", "Dec": "12" }
      const date = str?.split("-");
      return `${date[2]}-${month[date[1]]}-${date[0]}`;
    }
    return;
  }
  const [formData, SetFormData] = useState({
    ...capturedData?.details_of,
    income: capturedData?.investorType_details?.income,
    dob: capturedData.details_of?.dob,
    doc_pan: "",
    doc_aadhar: ""
  });

  const fileChangeHandler = event => {

    const { name, files } = event.target;
    console.log(name, files)
    SetFormData((prev) => ({
      ...prev,
      [name]: files[0]
    }))
  };

  const handleChange = event => {
    let { name, value } = event.target;
    SetFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeHandler = (name, value) => {
    SetFormData((prev) => ({
        ...prev,
        [name]: dateConverter(value)
    }))
}

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/directorDetails`,
        { ...formData, investorType: capturedData.investorType, doc_id: capturedData?.doc_id },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then(res => {
        const { data } = res;

        if (!data.succ) {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(data.message);
          return;
        }
        setIsLoading(false);
        setIsSuccess(true);
        setMessage(data.message);
        setCapturedData((prev) => ({
          ...prev,
          ["doc_id"]: data?.id
        }))
        setTimeout(() => {
          handleNext();
        }, 2000);
      })
      .catch((error) => {
        console.log("error : ", error);
        setIsLoading(false);
        setIsFailure(true);
        setMessage(error?.response?.data?.message,);
        return;
      });

  };

  return (
    <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Typography variant="subtitle1">{heading}</Typography>
          {Fields?.map((ele) => {
            return <div >

              {
                ele.type == 'radio' ? <RadioGroup row name={ele.name} value={formData[ele.name]} onChange={handleChange}>
                  <FormControlLabel value="spouse" control={<Radio />} label="Spouse Name" />
                  <FormControlLabel value="father" control={<Radio />} label="Father Name" />
                </RadioGroup> :
                  <>
                    {ele.type == "file" ? <TextField
                      label={ele.label}
                      onChange={fileChangeHandler}
                      type={ele.type}
                      name={ele.name}
                      required={ele.required}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      focused={ele.type == "date" || ele.type == "file"}
                      select={ele.dropdown}
                      error={!!validationErrors[ele.name]} // Check if the field has an error
                      helperText={validationErrors[ele.name]} /// Display the error message
                    >
                      {ele.dropdown && ele.value.map((val) =>
                        <MenuItem value={val || formData[ele.name]} >{val}</MenuItem>
                      )}
                    </TextField> : <> {ele.type == "date" ? <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack mt={2} mb={1}>
                        <DesktopDatePicker
                          label={ele.label}
                          inputFormat={"dd-MMM-yyyy"}
                          maxDate={dateConverter(new Date().getTime())}
                          value={formData[ele.name]}
                          openTo="day"
                          views={['year', 'month', 'day']}
                          onChange={(e)=>changeHandler(ele.name,e)}
                          renderInput={(params) => <TextField fullWidth value={formData[ele.name]}  name={ele.name}  {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider> : <TextField
                      label={ele.label}
                      onChange={handleChange}
                      type={ele.type}
                      name={ele.name}
                      value={formData[ele.name]}
                      required={ele.required}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      focused={ele.type == "date"}
                      select={ele.dropdown}
                      error={!!validationErrors[ele.name]} // Check if the field has an error
                      helperText={validationErrors[ele.name]} /// Display the error message
                    >
                      {ele.dropdown && ele.value.map((val) =>
                        <MenuItem value={val || formData[ele.name]} >{val}</MenuItem>
                      )}
                    </TextField>}</>}
                  </>

              }
            </div>
          })}
          {/* <RadioGroup row name="name_of" onChange={handleChange}>
            <FormControlLabel value="spouse" control={<Radio />} label="Spouse Name" />
            <FormControlLabel value="father" control={<Radio />} label="Father Name" />
          </RadioGroup> */}
          {/* <TextField
            label="Name"
            onChange={handleChange}
            value={form.name}
            name="name"
            required
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.Name} // Check if the field has an error
            helperText={validationErrors.Name} /// Display the error message
          />
          <TextField
            label="Father/Spouse Name"
            onChange={handleChange}
            value={Data.fs_name}
            name="fs_name"
            required
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.phone} // Check if the field has an error
            helperText={validationErrors.phone} // Display the error message
          />

          <TextField
            label="Date of Birth"
            type="date"
            name="dob"
            // value={formData.dob}
            onChange={handleChange}
            variant="standard"
            margin="normal"
            fullWidth
            error={!!validationErrors.dob} // Check if the field has an error
            helperText={validationErrors.dob} // Display the error message
            focused
          />
          <TextField
            label="Gender"
            onChange={handleChange}
            name="gender"
            required
            select
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.Gender} // Check if the field has an error
            helperText={validationErrors.Gender} // Display the error message
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </TextField>

          <TextField
            label="Email ID"
            onChange={handleChange}
            name="email"
            required
            value={Data.email}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.email} // Check if the field has an error
            helperText={validationErrors.email} // Display the error message
          ></TextField>
          <TextField
            label="Phone No"
            onChange={handleChange}
            name="phone"
            required
            value={Data.phone}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.phone} // Check if the field has an error
            helperText={validationErrors.phone} // Display the error message
          ></TextField>

          <Typography sx={{ mt: 4, ml: "35%" }} variant="subtitle1">
            Upload Related Docs
          </Typography>
          <TextField
            label="Upload Pan Card of Director"
            onChange={fileChangeHandler}
            name="pancard"
            type="file"
            required
            variant="outlined"
            helperText="Only PDF format allowed"
            hidden
            focused
            margin="normal"
            fullWidth
          ></TextField>
          <TextField
            label="Upload Aadhar Card of Director"
            onChange={fileChangeHandler}
            name="aadharcard"
            type="file"
            required
            variant="outlined"
            helperText="Only PDF format allowed"
            hidden
            focused
            margin="normal"
            fullWidth
          ></TextField> */}
          <Box sx={{ display: "flex", flex: "row", gap: 4 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {
                "Back"
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </form>
      </CardContent>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        message=""
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={() => setIsFailure(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default DirectorsDetails;
