import { Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { error } from "console";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductCode } from "../../Data/data";

function FoliosList({ accessToken }) {
  const [folios, setFolios] = useState([]);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "SN",
      width: 80,
      renderHeader: () => <strong>SN</strong>,
    },
    {
      field: "user",
      headerName: "Name",
      width: 160,
      renderHeader: () => <strong>Name</strong>,
    },
    {
      field: "pan",
      headerName: "PAN",
      width: 150,
      renderHeader: () => <strong>PAN</strong>,
    },
    {
      field: "amc",
      headerName: "AMC",
      width: 100,
      renderHeader: () => <strong>AMC</strong>,
    },
    {
      field: "scheme",
      headerName: "Scheme",
      width: 300,
      renderHeader: () => <strong>Scheme</strong>,
      renderCell: (params) => {
        let { scheme, amc } = params.row;
        const schemName =
          ProductCode.find(
            (data) => ((data.scheme_code == scheme)||(data.AMC+data.scheme_code == scheme)) && data.AMC == amc
          )?.name || "";

        return (
          <div
            style={{
              minHeight: "50px",
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {schemName}
          </div>
        );
      },
    },
    {
      field: "folio_number",
      headerName: "Folio Number",
      width: 220,
      renderHeader: () => <strong>Folio Number</strong>,
    },
    {
      field: "units",
      headerName: "Units",
      width: 280,
      renderHeader: () => <strong>Units</strong>,
    },
  ]);

  const getFoliosData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/super/nse/folio-list`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { pan: state.folio.pan },
        }
      );
      const { folioData, message, succ } = data;
      if (!succ) {
        throw Error(message);
      }
      setFolios(folioData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFoliosData();
  }, []);

  return (
    <Grid
      item
      xs={12}
      px={3}
      mt={4}
      sx={{ maxWidth: "100vw", height: "100vh" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "600",
            margin: "0px 0px 17px 5px",
          }}
        >
          All Folios
        </Typography>
      </div>
      <div style={{ height: "100vh", width: "100%" }}>
        <DataGrid
          sx={{ mt: 1 }}
          getRowHeight={() => "auto"}
          rows={folios.map((each: any, idx: number) => {
            return { ...each, id: idx + 1 };
          })}
          columns={columns.map((each, idx) => {
            return {
              id: idx + 1,
              ...each,
              headerAlign: "center",
              align: "center",
              sx: {
                size: 4,
              },
            };
          })}
        />
      </div>
    </Grid>
  );
}

export default FoliosList;
