import { Grid, Stack, Typography, Snackbar, Alert, Box, Button, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loading from '../Dashboard/Loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RTGSPendingPayment from './CreateOrder/RTGSPendingPayment';
import { useAppContext } from '../../Store/AppContext';
import { FormatNumber } from '../../utils/formatNumber';
import BeneficiaryDetails from './BeneficiaryDetails';
import CardsMobileRes from './CardMobileRes';
import { ProductCode } from '../../Data/data';

export default function TransactionPending({ accessToken }) {
    const [loading, setLoading] = useState(false);
    const [storeState, dispatch] = useAppContext();
    const [formData, setFormData] = useState();
    const [tranx, setTranx] = useState([]);
    const navigate = useNavigate();
    const [isError, setError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isShowBeneficiary, setShowBeneficiary] = useState(false);
    const [message, setMessage] = useState("");
    const [isModel, setIsModel] = useState(false);

    const [columns, setColumns] = useState([
        {
            field: 'id', headerName: 'S.No', width: 90, renderHeader: () => (
                <strong>
                    {'S.No'}
                </strong>
            ),
        },
        {
            field: 'TransactionData', headerName: 'Fund', width: 450, renderHeader: () => (
                <strong>
                    {'Fund'}
                </strong>
            ),
            renderCell: (params) => {
                let { TransactionData } = params.row;
                const fund = ProductCode.filter((ele) => ele.AMC == TransactionData[0]?.Funds && ele.code == TransactionData[0]?.Scheme)[0]?.name;
                return <div style={{ minHeight: "50px", alignItems: "center", display: "flex", width: "100%" }}>{fund} and others</div>
            }
        },
        {
            field: 'ac_no',
            headerName: 'Account No.',
            width: 170, renderHeader: () => (
                <strong>
                    {'Account No.'}
                </strong>
            ),
            renderCell: (params) => {
                let { ac_no } = params.row;
                return <div>{ac_no}</div>;
            },
        },
        {
            field: 'AMOUNT', headerName: 'Amount', width: 120, renderHeader: () => (
                <strong>
                    {'Amount'}
                </strong>
            ), renderCell: (params) => {
                let { instrm_amount } = params.row;
                return <div>{FormatNumber(instrm_amount)}</div>;
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created Date',
            width: 120, renderHeader: () => (
                <strong>
                    {'Created Date'}
                </strong>
            ),
            renderCell: (params) => {
                let { createdAt } = params.row;
                return <div>{createdAt.split("T")[0]}</div>;
            },
        },
        {
            field: 'ash', headerName: 'Actions', width: 240, renderHeader: () => (
                <strong>
                    {'Actions'}
                </strong>
            ), renderCell: (params) => {

                return <div><Grid
                    item
                    className="bills-pay"
                    py={1}
                    px={2}
                    style={{ marginRight: "1rem" }}
                    onClick={() => {
                        setFormData(params.row);
                        setIsModel(true);
                    }}
                >
                    Continue
                </Grid></div>;
            },

        },
    ]);

    const getTranxData = () => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/getpendingtrans`,
                { iin: storeState.ACTIVEINVETOR?.folio.Folio },
                { headers: { Authorization: `Bearer ${accessToken}` } })
            .then(({ data }) => {
                setLoading(false)
                if (!data.succ) {
                    setError(true);
                    setMessage(data.message);
                    return;
                }
                setTranx(data.Transaction);
                return;
            }).catch((error) => {
                setLoading(false)
                setError(true);
                setMessage("Failed to Fetching Data from Server")
                return;
            })
    }
    useEffect(() => {
        getTranxData()
    }, [isModel])

    const showBeneficiaryHandler = () => {
        setShowBeneficiary(true)
    }
    const handleClose = () => setShowBeneficiary(false);

    return <Grid item xs={12} sx={{ ml: { xs: 3, md: 4 } }}>
        <Snackbar
            open={isError}
            autoHideDuration={4000}
            onClose={() => setError(false)}
        >
            <Alert severity='error'
            >{message}</Alert>
        </Snackbar>
        <Snackbar
            open={isSuccess}
            autoHideDuration={4000}
            onClose={() => setIsSuccess(false)}
        >
            <Alert severity='success'
            >{message}</Alert>
        </Snackbar>
        <Box sx={{ height: "45px", marginTop: "10px", width: { xs: "98%", md: "99.3%" }, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <Button sx={{ backgroundColor: "#318ad6", color: "white", "&:hover": { backgroundColor: "#318ad6" } }}
                onClick={showBeneficiaryHandler}
            >
                Show Beneficiary Details
            </Button>
            <BeneficiaryDetails isShowBeneficiary={isShowBeneficiary} setShowBeneficiary={setShowBeneficiary} iin={storeState.ACTIVEINVETOR?.folio.Folio} />
        </Box>
        <Box sx={{ height: '80vh', display: { xs: "none", sm: "block" }, width: { xs: "96vw", md: '80vw' } }}>
            {loading ? <Loading /> : <DataGrid
                sx={{ mt: 2 }}
                //  hideFooter={true}
                rowsPerPageOptions={[50, 100, 1000]}

                rows={tranx.map((each: any, idx: number) => {
                    return { ...each, id: idx + 1 };
                })}
                columns={columns.map((each, idx) => {
                    return {
                        id: idx + 1,
                        ...each, headerAlign: 'center',
                        align: 'center',
                        sx: {
                            size: 2,
                        },
                    }
                })}
            />}
        </Box>
        {loading ? <Loading /> : <Box sx={{ display: { xs: "flex", sm: "none" }, flexDirection: "column", alignItems: "center", width: "100%"}}>
            {tranx.map((ele) => {
                let { TransactionData, instrm_amount, createdAt, ac_no }: any = ele;
                const fund = ProductCode.filter((ele) => ele.code == TransactionData[0]?.Scheme)[0]?.name;
                return <CardsMobileRes
                    scheme={fund}
                    Amount={instrm_amount}
                    date={createdAt?.split("T")[0]}
                    TranType={ac_no}
                    TranStatus={""}
                    isBtn={true}
                    Data = {ele}
                    setFormData={setFormData}
                    setIsModel={setIsModel}
                />
            })}
        </Box>}
        {isModel && <RTGSPendingPayment isModel={isModel} setError={setError} isError={isError} setIsSuccess={setIsSuccess} msg={message} setMsg={setMessage} setIsModel={setIsModel} formData={formData} accessToken={accessToken} />}
    </Grid>
}