import React, { createContext, useContext, useEffect, useState } from "react";
import socket from "../socket";
export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socketData, setSocketData] = useState();

  useEffect(() => {
    socket.on("get_gold_price", (data) => {
      //   console.log("Get Price For gold ->", data);
      setSocketData(data);
    });
    // }
  }, []);

  return (
    <SocketContext.Provider value={{ socketData }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
