import { DataGrid } from "@mui/x-data-grid";
import { FormatNumberRedeem } from "../../utils/formatNumber";
import { useState } from "react";
import { Typography } from "@mui/material";

function HoverData({ data }) {
  const [headers, setColumns] = useState([
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      renderHeader: () => <strong>{"S.No"}</strong>,
      renderCell: (params) => {
        let { id,earn_amount } = params.row;
        return <Typography
        color={earn_amount < 0 ? "red" : ""}
        sx={{ textAlign: "end" }}
        variant="caption"
        >
        {id}
        </Typography>;
      },
    },

    {
      field: "Fund_Description",
      headerName: "Scheme",
      width: 350,
      renderHeader: () => <strong>{"Scheme"}</strong>,
      renderCell: (params) => {
        let { Fund_Description,earn_amount } = params.row;
        return <Typography
        variant="caption"
        color={earn_amount < 0 ? "red" : ""}
        sx={{ textAlign: "start",minHeight:"50px",display:"flex",alignItems:"center"}}
        >
        {Fund_Description}
        </Typography>;
      },
    },
    {
      field: "earn_amount",
      headerName: "Day's Gain",
      width: 160,
      renderHeader: () => <strong>{"Day's Gain"}</strong>,
      renderCell: (params) => {
        let { earn_amount } = params.row;
        return <Typography
        variant="caption"
        color={earn_amount < 0 ? "red" : ""}
        sx={{ textAlign: "end" }}
        >
        {FormatNumberRedeem(earn_amount)}
        </Typography>;
        }
    },
    {
      field: "gain_percentage",
      headerName: "%",
      width: 80,
      renderHeader: () => <strong>{"%"}</strong>,
      renderCell: (params) => {
        let { gain_percentage,earn_amount } = params.row;
        return <Typography
        variant="caption"
        color={earn_amount < 0 ? "red" : ""}
        sx={{ textAlign: "end" }}
        >
        {FormatNumberRedeem(gain_percentage)} %
        </Typography>;
      },
    },
  ]);


  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "0",
        padding: "5px",
        backgroundColor: "#2f85d7",
        borderRadius: "10px",
        display: "flex",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
    >
      <DataGrid
        sx={{ mt: 2, width: "45vw", height: "50vh", backgroundColor: "white" }}
        hideFooter={true}
        getRowHeight={() => 'auto'}
        rows={data.map((each: any, idx: number) => {
          return { ...each, id: idx + 1 };
        })}
        columns={headers}
      />
    </div>
  );
}

export default HoverData;