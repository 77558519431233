import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { FormatNumber, ConvertToPercentage } from "../../../utils/formatNumber";
import { useAppContext } from "../../../Store/AppContext";
import DefaultPage from "../DefaultPage";
import AccountModal from "./AccountModal";
import KycModal from "./KycModal";
import BankModal from "./BankModal";
import PaymentPage from "./CashFreePayment";
import { SocketContext } from "../../../Store/SocketContext";
import TabsButtons from "../TabsButtons";
import InfoIcon from "@mui/icons-material/Info";

export default function GoldInvestment({ user, accessToken }) {
  const { socketData } = useContext(SocketContext);

  const [userData, setUserData] = useState<any>(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [openAccount, setOpenAccount] = useState<boolean>(false);
  const [isKycOpen, setIsKycOpen] = useState<boolean>(false);
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);
  const [storeState, dispatch] = useAppContext();
  const [tranx, setTranx] = useState<any>([]);
  const [Data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [NavList, setNavList] = useState<any>([]);
  const [totalSum, setTotalSum] = useState({
    totalInvested: 0,
    totalCurrentValue: 0,
    totalEarnedYesterDay: 0,
    totalEarnedTillDate: 0,
  });
  const navigate = useNavigate();

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list);

  const [goldBuyPrice, setGoldBuyPrice] = useState<number>(0);
  const [silverBuyPrice, setSilverBuyPrice] = useState<number>(0);

  const [goldSellPrice, setGoldSellPrice] = useState<number>(0);
  const [silverSellPrice, setSilverSellPrice] = useState<number>(0);

  const [blockId, setBlockId] = useState<string>("");

  const fetchAccountData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/profile`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let data = response.data.data;
      setUserData(data);
      setIsUpdated(false);
      // console.log("User Response ->", response.data.data);
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  // console.log("user ->", user);

  useEffect(() => {
    if (isUpdated) fetchAccountData();
  }, [isUpdated]);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const getTransactionReports = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/investment/transactionsReport`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(async (res) => {
        const { data } = res;

        if (!data.succ) {
          setIsFail(true);
          setMsg(data.message);
          setData({});
          setTotalSum({
            totalCurrentValue: 0,
            totalEarnedTillDate: 0,
            totalEarnedYesterDay: 0,
            totalInvested: 0,
          });
          setEmpty(false);
          return;
        }

        setData(data.data);

        // if (Object.keys(Data).length > 0) {
        //   setData(Data);

        //   setTotalSum((totalSum) => ({
        //     ...totalSum,
        //     ...TotalEarn,
        //   }));
        // } else {
        //   setData({});
        //   setTotalSum({
        //     totalCurrentValue: 0,
        //     totalEarnedTillDate: 0,
        //     totalEarnedYesterDay: 0,
        //     totalInvested: 0,
        //   });
        //   setEmpty(false);
        //   return;
        // }
      })
      .catch((error) => {
        setData({});
        setTotalSum({
          totalCurrentValue: 0,
          totalEarnedTillDate: 0,
          totalEarnedYesterDay: 0,
          totalInvested: 0,
        });
        setEmpty(false);
        setMsg(error);
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    getTransactionReports();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [storeState.ACTIVEINVETOR]);

  return (
    <>
      <TabsButtons investment={"gold"} page={"home"} />

      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Gold Investment
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={4}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      Create Account
                    </Typography>
                    {userData ? (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setOpenAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => setOpenAccount(true)}
                      >
                        Pending
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ marginTop: 5 }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      KYC
                    </Typography>
                    {userData?.user?.kyc_status === 0 ? (
                      <Button
                        variant="contained"
                        onClick={() => setIsKycOpen(true)}
                      >
                        Pending
                      </Button>
                    ) : userData?.kyc_status === 1 ? (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setOpenAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4}>
              <Card sx={{ alignSelf: "start" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }} variant="h6">
                      Bank Details
                    </Typography>

                    {userData?.bank ? (
                      <Button
                        variant="contained"
                        color="success"
                        // onClick={() => setOpenAccount(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => setIsOpenBank(true)}
                      >
                        Pending
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, pl: 6, pr: 6 }}>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: 600 }} variant="h6" sx={{ mb: 2 }}>
            Scheme Wise Report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => navigate("/dashboardAdmin/goldInvesting")}
          >
            Buy More
          </Button>
        </Box> */}
        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            overflow: { xs: "scroll", sm: "hide" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              minWidth: { xs: "50rem", sm: "69vw" },
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Gold / Silver
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Units
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Amount Invested (Excluding Tax.)
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 600, color: "grey" }}
                variant="body1"
              >
                Actions
              </Typography>
            </Grid>
          </Box>
          <Divider
            sx={{ mb: 2, mt: 3, minWidth: { xs: "50rem", sm: "69vw" } }}
          />
          {!isEmpty &&
            Object.entries(Data)?.map((each, idx) => {
              const key = each[0];
              const value: any = each[1];
              let currentValue = 0;

              switch (key) {
                case "silver":
                  currentValue = value.quantity * silverSellPrice;
                  break;
                case "gold":
                  currentValue = value.quantity * goldSellPrice;
                  break;
              }

              return (
                <>
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      minWidth: { xs: "50rem", sm: "69vw" },
                      overflowX: { xs: "scroll", sm: "hidden" },
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        display="flex"
                        alignItems="center"
                        gap={0.5}
                      >
                        {FormatNumber(value.quantity)}
                        <Tooltip
                          title={
                            <Box sx={{ fontSize: "1rem", textAlign: "center" }}>
                              <div>Purchase : {value.purchaseQuantity}</div>
                              <div>Gift : {value.giftQuantity}</div>
                            </Box>
                          }
                          arrow
                          placement="top" // Ensures tooltip appears above the icon
                        >
                          <InfoIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6">
                          {FormatNumber(value.investedAmount)}
                        </Typography>
                        {/* <Typography
                          variant="caption"
                          color={value.gain < 0 ? "red" : "#32CD32"}
                          sx={{ textAlign: "end" }}
                        >
                          {value.gain < 0
                            ? FormatNumber(value?.gain)
                            : `+ ${FormatNumber(value?.gain)}`}
                        </Typography> */}
                      </div>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            width: "100px",
                            borderRadius: "5px",
                            textAlign: "center",
                            color: "white",
                            backgroundColor: "#ffa500",
                            cursor: "pointer",
                          }}
                          onClick={(e) =>
                            navigate(`/dashboardAdmin/goldInvesting`, {
                              state: { value },
                            })
                          }
                        >
                          Buy More
                        </div>
                        <div
                          style={{
                            padding: "10px",
                            width: "100px",
                            borderRadius: "5px",
                            textAlign: "center",
                            color: "white",
                            backgroundColor: "#318cd6",
                            cursor: "pointer",
                          }}
                          onClick={(e) =>
                            navigate(`/dashboardAdmin/goldRedeem`, {
                              state: {
                                key: key,
                                value: value,
                              },
                            })
                          }
                        >
                          Redeem
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  {idx !== tranx.length - 1 && (
                    <Divider
                      sx={{
                        mt: 2,
                        mb: 2,
                        minWidth: { xs: "50rem", sm: "69vw" },
                      }}
                    />
                  )}
                </>
              );
            })}
        </Box>
      </Paper>
      {openAccount && (
        <AccountModal
          openAccount={openAccount}
          setOpenAccount={setOpenAccount}
          accessToken={accessToken}
          userData={userData?.user ? userData?.user : user}
          userAddress={userData?.address}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isKycOpen && (
        <KycModal
          isKycOpen={isKycOpen}
          setIsKycOpen={setIsKycOpen}
          userData={userData?.user}
          panData={
            userData?.user?.pan
              ? userData?.user?.pan?.panNumber
              : user.panNumber
          }
          accessToken={accessToken}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {isOpenBank && (
        <BankModal
          isOpenBank={isOpenBank}
          setIsOpenBank={setIsOpenBank}
          userData={userData?.user}
          bank={userBank}
          accessToken={accessToken}
          setSuccess={setSuccess}
          setIsUpdated={setIsUpdated}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      {/* <PaymentPage accessToken={accessToken} /> */}

      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="error">{msg}</Alert>
      </Snackbar>
    </>
  );
}
