import {
  Grid,
  Paper,
  Typography,
  Box,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Store/AppContext";
import axios from "axios";
import { Circle } from "@mui/icons-material";
import TabsButtons from "./TabsButtons";

function DefaultPage({ accessToken }) {
  const navigate = useNavigate();
  const [storeState, dispatch] = useAppContext();
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/status/iin`,
        {
          params: {
            iin: storeState.ACTIVEINVETOR?.folio.Folio,
            folio_id: storeState.ACTIVEINVETOR?.folio._id,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(async (res) => {
        const { data } = res;
        if (!data.succ) {
          setError(true);
          setMsg(data.message);
          return;
        }
        setSuccess(true);
        setMsg(data.message);
        //@ts-ignore
        dispatch({
          type: "SET_ACTIVE_INVESTORS",
          payload: { ...storeState.ACTIVEINVETOR, isIINVerifid: true },
        });
        navigate("/dashboardAdmin/hometabs");
        return;
      });
  }, []);

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  return (
    <>
      <TabsButtons investment="mutual_fund" page="home" />;
      <Grid
        container
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Paper elevation={2} sx={{ padding: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                mt: 2,
                mb: 1,
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {msg.length < 2 ? (
                "Thank you!, Your are done with all the steps. Please wait for IIN activation from NSE"
              ) : (
                <>
                  {msg.split(",").map((ele) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Circle sx={{ fontSize: "0.7rem" }} />
                          <Typography> {ele}</Typography>
                        </Box>
                        {ele
                          .toLocaleLowerCase()
                          .includes("iin conformation lin") && (
                          <Typography sx={{ fontSize: "0.8rem", color: "red" }}>
                            {" "}
                            {
                              "Please confirm the Investor Account Creation from the mail"
                            }
                          </Typography>
                        )}
                        {ele.toLocaleLowerCase().includes("kyc") && (
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() =>
                              navigate("/dashboardAdmin/check/kyc")
                            }
                            sx={{ marginTop: 2 }}
                          >
                            KYC
                          </Button>
                        )}
                        {ele.toLocaleLowerCase().includes("cheque") && (
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() =>
                              navigate("/dashboardAdmin/upload/cancelcheque", {
                                state: storeState.ACTIVEINVETOR?.folio.Folio,
                              })
                            } //sending IIN number
                            sx={{ marginTop: 2 }}
                          >
                            Upload Cancel Cheque
                          </Button>
                        )}
                      </Box>
                    );
                  })}
                </>
              )}
            </Typography>
          </Box>
        </Paper>
        <Snackbar
          open={isSuccess}
          autoHideDuration={6000}
          onClose={closeHandleSnackbar}
        >
          <Alert severity="success">{msg}</Alert>
        </Snackbar>
        <Snackbar
          open={isError}
          autoHideDuration={6000}
          onClose={closeHandleSnackbar}
        >
          <Alert severity="error">{msg}</Alert>
        </Snackbar>
      </Grid>
    </>
  );
}

export default DefaultPage;
