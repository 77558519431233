import { Grid, Stack, Typography, Snackbar, Alert, Box, Button, Modal, TextField, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Loading from '../Dashboard/Loading';
import { useAppContext } from '../../Store/AppContext';
import { FormatNumber } from '../../utils/formatNumber';
import { exportToCSV } from '../../utils/convertToExcel'
import { Download } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

export default function TransactionReports({ accessToken }) {
    const { state }:any = useLocation();
    const [loading, setLoading] = useState(false);
    const [tabName, setTabname] = useState("Tradewise") //Tradebook
    const [tranx, setTranx] = useState([]);
    const [isError, setError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [tranxTrasewise,setTranxTradewise]=useState([]);
    const [formData, setFormData] = useState<any>({
        id: 1,
        date: ['2024-04-01', '2025-03-31']
    });


    const FinancialYear = [
        { id: 1, name: "FY 2024-25", value: ['2024-04-01', '2025-03-31'] },
        { id: 2, name: "FY 2023-24", value: ['2023-04-01', '2024-03-31'] },
    ]

    const ViewDate = (str) => {
        var date = new Date(str);
        var mnth = ("0" + date.getMonth()).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
        var year = date.getFullYear();
        return `${day}-${mnth}-${year}`;
    }

    const [columns1, setColumns1] = useState([
        {
            field: 'id', headerName: 'S.No', width: 90, renderHeader: () => (
                <strong>
                    {'S.No'}
                </strong>
            ),
        },
        {
            field: 'Fund_Description', headerName: 'Scheme', width: 360, renderHeader: () => (
                <strong>
                    {'Scheme'}
                </strong>
            ),
            renderCell: (params) => {
                let { Fund_Description } = params.row;
                return <div style={{minHeight:"50px",alignItems:"center",display:"flex",width:"100%"}}>{Fund_Description}</div>
            }
        },
        {
            field: 'Transaction_Date',
            headerName: 'Transaction Date',
            width: 240, renderHeader: () => (
                <strong>
                    {'Transaction Date'}
                </strong>
            ),
            renderCell: (params) => {
                let { Transaction_Date } = params.row;
                return <div>{Transaction_Date}</div>;
            },
        },
        {
            field: 'Transaction_Description', headerName: 'Trade Type', width: 120, renderHeader: () => (
                <strong>
                    {'Trade Type'}
                </strong>
            ), renderCell: (params) => {
                let { Transaction_Description } = params.row;
                return <>
                    {/* {Transaction_Description.includes("Purchase") ? <div style={{ display: "flex" ,minHeight: "50px", width: "120px", justifyContent: "center", alignItems: "center", fontWeight: "550", backgroundColor: "#c6e0b4" }}> */}
                    {/Purchase|Systematic Investment|Systematic-NSE/.test(Transaction_Description) ? <div style={{ display: "flex" ,minHeight: "50px", width: "120px", justifyContent: "center", alignItems: "center", fontWeight: "550", backgroundColor: "#c6e0b4" }}>
                        Buy
                    </div> : <div style={{ display: "flex", minHeight: "50px", width: "120px", justifyContent: "center", alignItems: "center", backgroundColor: "#ffe699", fontWeight: "550" }}>Sell</div>}
                </>
            },
        },
        {
            field: 'Units',
            headerName: 'Quantity',
            width: 120, renderHeader: () => (
                <strong>
                    {'Quantity'}
                </strong>
            ),
            renderCell: (params) => {
                let { Units } = params.row;
                return <div>{FormatNumber(Units)}</div>;
            },
        },
        {
            field: 'c',
            headerName: 'Price',
            width: 120, renderHeader: () => (
                <strong>
                    {'Price'}
                </strong>
            ),
            renderCell: (params) => {
                let { Nav } = params.row;
                return <div>{FormatNumber(Nav)}</div>;
            },

        },
        {
            field: 'hb',
            headerName: 'Value',
            width: 120, renderHeader: () => (
                <strong>
                    {'Value'}
                </strong>
            ),
            renderCell: (params) => {
                let { Units } = params.row;
                let { Nav } = params.row;
                const ans = Number(Units) * Number(Nav);
                return <div>{FormatNumber(ans)}</div>;
            },

        },
    ]);

    const [columns2, setColumns2] = useState([
        {
            field: 'id', headerName: 'S.No', width: 90, renderHeader: () => (
                <strong>
                    {'S.No'}
                </strong>
            ),
        },
        {
            field: 'scheme', headerName: 'Scheme', width: 360, renderHeader: () => (
                <strong>
                    {'Scheme'}
                </strong>
            ),
            renderCell: (params) => {
                let { scheme } = params.row;
                return <div style={{minHeight:"50px",alignItems:"center",display:"flex",width:"100%"}}>{scheme}</div>
            }
        },
        {
            field: 'entry_date',
            headerName: 'Entry Date',
            width: 240, renderHeader: () => (
                <strong>
                    {'Entry Date'}
                </strong>
            ),
            renderCell: (params) => {
                let { entry_date } = params.row;
                return <div>{entry_date}</div>;
            },
        },
        {
            field: 'exist_date', headerName: 'Exit Date', width: 240, renderHeader: () => (
                <strong>
                    {'Exit Date'}
                </strong>
            ), renderCell: (params) => {
                let { exist_date } = params.row;
                return <div>{exist_date}</div>
            },
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 120, renderHeader: () => (
                <strong>
                    {'Quantity'}
                </strong>
            ),
            renderCell: (params) => {
                let { quantity } = params.row;
                return <div>{FormatNumber(quantity)}</div>;
            },
        },
        {
            field: 'buy_value',
            headerName: 'Buy Value',
            width: 120, renderHeader: () => (
                <strong>
                    {'Buy Value'}
                </strong>
            ),
            renderCell: (params) => {
                let { buy_value } = params.row;
                return <div>{FormatNumber(buy_value)}</div>;
            },

        },
        {
            field: 'sell_value',
            headerName: 'Sell Value',
            width: 120, renderHeader: () => (
                <strong>
                    {'Sell Value'}
                </strong>
            ),
            renderCell: (params) => {
                let { sell_value } = params.row;
                return <div>{FormatNumber(sell_value)}</div>;
            },

        },
        {
            field: 'profit',
            headerName: 'Profit',
            width: 120, renderHeader: () => (
                <strong>
                    {'Profit'}
                </strong>
            ),
            renderCell: (params) => {
                let { profit } = params.row;
                return <div>{FormatNumber(profit)}</div>;
            },

        },
        {
            field: 'period_of_holding',
            headerName: 'Period of Holding (in days)',
            width: 185, renderHeader: () => (
                <strong>
                    {'Period of Holding (in days)'}
                </strong>
            ),
            renderCell: (params) => {
                let { period_of_holding } = params.row;
                return <div>{period_of_holding}</div>;
            },

        },
        {
            field: 'taxable_profit_short',
            headerName: 'Taxable Profit(Short Term)',
            width: 185, renderHeader: () => (
                <strong>
                    {'Taxable Profit(Short Term)'}
                </strong>
            ),
            renderCell: (params) => {
                let { taxable_profit_short } = params.row;
                return <div>{FormatNumber(taxable_profit_short)}</div>;
            },

        },
        {
            field: 'taxable_profit_long',
            headerName: 'Taxable Profit(Long Term)',
            width: 180, renderHeader: () => (
                <strong>
                    {'Taxable Profit(Long Term)'}
                </strong>
            ),
            renderCell: (params) => {
                let { taxable_profit_long } = params.row;
                return <div>{FormatNumber(taxable_profit_long)}</div>;
            },

        },
    ]);

    const getTranxData = () => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_BACKEND_HOST}v1/super/nse/transactions/tradebook`,
                { pan: state.folio.pan , date: formData.date},
                { headers: { Authorization: `Bearer ${accessToken}` } })
            .then(({ data }) => {
                setLoading(false)
                if (!data.succ) {
                    setError(true);
                    setMessage(data.message);
                    return;
                }
                setTranx(data.Transaction);
                return;
            }).catch((error) => {
                console.log(error)
                setLoading(false)
                setError(true);
                setMessage("Failed to Fetching Data from Server")
                return;
            })
    }
    const getTranxDataTrasewise = () => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_BACKEND_HOST}v1/super/nse/transactions/tradewise`,
                { pan: state.folio.pan, date: formData.date},
                { headers: { Authorization: `Bearer ${accessToken}` } })
            .then(({ data }) => {
                setLoading(false)
                if (!data.succ) {
                    setError(true);
                    setMessage(data.message);
                    return;
                }
                setTranxTradewise(data.Transaction);
                return;
            }).catch((error) => {
                console.log(error)
                setLoading(false)
                setError(true);
                setMessage("Failed to Fetching Data from Server")
                return;
            })
    }
    useEffect(() => {
      
        getTranxData()
        getTranxDataTrasewise()
       
    }, [formData])

    const downloadHandlerTradebook = () => {
        try {
            exportToCSV(tranx,tranxTrasewise, { PAN: state?.folio?.pan, NAME: state?.user_id?.name, Interval: FinancialYear[0].value });
            setIsSuccess(true);
            setMessage("Report is downloading...")
        } catch (error: any) {
           setError(true);
           setMessage(error.message);
           return;
        }
    }
    const downloadHandlerTradewise = () => {
        try {
            exportToCSV(tranx,tranxTrasewise, { PAN: state?.folio?.pan, NAME: state?.user_id?.name, Interval: FinancialYear[0].value });
            setIsSuccess(true);
            setMessage("Report is downloading...")
        } catch (error: any) {
            setError(true);
            setMessage(error.message);
            return;
        }
    }
    const TabChangeHandler = (arg) => {
        setTabname(arg);
    }

    const setFinancialYear = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormData((prev) => ({
            [name]: value,
            "date": FinancialYear[value - 1].value
        }));
    }

    if (loading) return <Loading />;
    return <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", alignItems: "center",marginTop:"5vh" }}>
        <Box sx={{ display: "flex", flexDirection: "row", width: "76vw", alignItems: "flex-start", gap: 2 }} >
            <Button variant={tabName == "Tradewise" ? "contained" : "outlined"} onClick={() => TabChangeHandler("Tradewise")} >Tradewise Exits</Button>
            <Button variant={tabName == "Tradebook" ? "contained" : "outlined"} onClick={() => TabChangeHandler("Tradebook")} >Trade Book</Button>
        </Box>
        <Box>
            <Grid item xs={12} ml={2}>
                <Snackbar
                    open={isError}
                    autoHideDuration={4000}
                    onClose={() => setError(false)}
                >
                    <Alert severity='error'
                    >{message}</Alert>
                </Snackbar>
                <Snackbar
                    open={isSuccess}
                    autoHideDuration={4000}
                    onClose={() => setIsSuccess(false)}
                >
                    <Alert severity='success'
                    >{message}</Alert>
                </Snackbar>
                <Box sx={{ display: "flex", height: "2.5rem", marginBottom: "1rem", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: 2 }}>
                    <Button variant='contained' sx={{ height: "2.5rem" }} endIcon={<Download />} onClick={tabName == "Tradebook" ? downloadHandlerTradebook : downloadHandlerTradewise}>Report</Button>
                    <TextField
                        label="Select Financial Year"
                        name="id"
                        onChange={setFinancialYear}
                        variant="outlined"
                        value={formData.id}
                        margin="normal"
                        size='small'
                        sx={{ width: "10rem", marginTop: "10px" }}
                        required
                        select
                    >
                        {FinancialYear.map((each, idx) => (
                            <MenuItem key={idx} value={each.id}>
                                {each.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                {tabName == "Tradewise" ? <>
                    <div style={{ height: '80vh', width: '78vw' }}>
                        {loading ? <Loading /> : <DataGrid
                            sx={{ mt: 1 }}
                            getRowHeight={() => 'auto'}
                            //  hideFooter={true}
                            rowsPerPageOptions={[50, 100, 1000]}
                            rows={tranxTrasewise.map((each: any, idx: number) => {
                                return { ...each, id: idx + 1 };
                            })}
                            columns={columns2.map((each, idx) => {
                                return {
                                    id: idx + 1,
                                    ...each, headerAlign: 'center',
                                    align: 'center',
                                    sx: {
                                        size: 4,
                                    },
                                }
                            })}
                        />}
                    </div>
                </> : <>
                    <div style={{ height: '80vh', width: '78vw' }}>
                        {loading ? <Loading /> : <DataGrid
                            sx={{ mt: 1 }}
                            getRowHeight={() => 'auto'}
                            //  hideFooter={true}
                            rowsPerPageOptions={[50, 100, 1000]}
                            rows={tranx.map((each: any, idx: number) => {
                                return { ...each, id: idx + 1 };
                            })}
                            columns={columns1.map((each, idx) => {
                                return {
                                    id: idx + 1,
                                    ...each, headerAlign: 'center',
                                    align: 'center',
                                    sx: {
                                        size: 4,
                                    },
                                }
                            })}
                        />}
                    </div>
                </>}
            </Grid>
        </Box>
    </Box>
}