const date = new Date().toLocaleDateString();
const InputFields = {
    Company: {
        "Details of Company":
            [{
                label: "Company Name",
                name: "name",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Company Contact Number",
                name: "phone",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Company Email",
                name: "email",
                type: "email",
                dropdown: false, required: true
            }]
        ,
        "Details of Director & Individual Related Docs": [
            {
                label: "Name",
                name: "name",
                type: "text",
                dropdown: false, required: true
            },
            {
                type:"radio",
                name:"rel_type",
                dropdown: false, required: true
            },
            {
                label: "Father/Spouse Name",
                name: "father",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Date of Birth",
                name: "dob",
                type: "date",
                dropdown: false, required: true
            },
            {
                label: "Gender",
                name: "gender",
                type: "text",
                dropdown: true, required: true,
                value: ["MALE", "FEMALE"]
            },
            {
                label: "Email ID",
                name: "email",
                type: "email",
                dropdown: false, required: true
            },
            {
                label: "Phone Number",
                name: "phone",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Upload Pan Card of Director",
                name: "doc_pan",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Upload Aadhar Card of Director",
                name: "doc_aadhar",
                type: "file",
                dropdown: false, required: true
            }],
        "List of Shareholders": [
            {
                label: "Name of shareholder",
                name: "name",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "No of shares held",
                name: "shares_no",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "% shareholding",
                name: "shares_per",
                type: "number",
                dropdown: false, required: true
            },
            {
                label: "PAN",
                name: "pan",
                type: "text",
                dropdown: false, required: true
            }
        ],
        "Company related documents": [
            {
                label: "Company PAN",
                name: "doc_comp_pan",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Certificate of Incorporation",
                name: "doc_certificate_incorporation",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Articles of Association",
                name: "doc_article_association",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Memorandum of Association",
                name: "doc_memorandum_association",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Balance Sheet & P & L for FY '22",
                name: "doc_balancesheet22",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Balance Sheet & P & L for FY '23",
                name: "doc_balancesheet23",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Cancelled Cheque",
                name: "doc_cancelled_cheque",
                type: "file",
                dropdown: false, required: true
            }
        ]
    },
    Partnership: {
        "Details of Partnership Firm": [
            {
                label: "Partnership Firm Name",
                name: "name",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Partnership Contact Number",
                name: "phone",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Partnership Email",
                name: "email",
                type: "email",
                dropdown: false, required: true
            },
            {
                name: "income",
                label: "Income Range",
                type: "text", //dropdowns
                dropdown: true, required: true,
                value: ["₹ 5L-10L", "₹ 10L-25L", "₹ 25L-1Cr", "> ₹ 1 Cr"]
            },
            {
                name: "networth",
                label: "Latest Net worth as on March 31 of last financial year",
                type: "text", //dropdowns
                dropdown: false, required: true,
                value: ["₹ 5L-10L", "₹ 10L-25L", "₹ 25L-1Cr", "> ₹ 1 Cr"]
            },
        ],
        "Details of Authorised Partner (for investments) & Partner related docs": [{
            label: "Name",
            name: "name",
            type: "text",
            dropdown: false, required: true
        },
        {
            type:"radio",
            name:"rel_type",
            dropdown: false, required: true
        },
        {
            label: "Father/Spouse Name",
            name: "father",
            type: "text",
            dropdown: false, required: true
        },
        {
            label: "Date of Birth",
            name: "dob",
            type: "date",
            dropdown: false, required: true
        },
        {
            label: "Gender",
            name: "gender",
            type: "text",
            dropdown: true, required: true,
            value: ["MALE", "FEMALE"]
        },
        {
            label: "Email ID",
            name: "email",
            type: "email",
            dropdown: false, required: true
        },
        {
            label: "Phone Number",
            name: "phone",
            type: "number",
            dropdown: false, required: true
        },
        {
            label: "Upload PAN card of Partner",
            name: "doc_pan",
            type: "file",
            dropdown: false, required: true
        },
        {
            label: "Upload Aadhaar card of Partner",
            name: "doc_aadhar",
            type: "file",
            dropdown: false, required: true
        }],
        "List of Partners": [
            {
                label: "Name of Partner",
                name: "name",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "PAN",
                name: "pan",
                type: "text",
                dropdown: false, required: true
            }
        ],
        "Partnership related documents": [
            {
                label: "Partnership PAN",
                name: "doc_comp_pan",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Address proof of Partnership firm",
                name: "doc_address_proof",
                type: "file",
                helperText:"Latest Bank statement of last 1 month or Bank pass book",
                dropdown: false, required: true
            },
            {
                label: "Partnership Deed",
                name: "doc_partnership_deed",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Cancelled Cheque",
                name: "doc_cancelled_cheque",
                type: "file",
                dropdown: false, required: true
            },
            {
                label: "Certificate of registration(if registered)",
                name: "doc_certificate_registration",
                type: "file",
                dropdown: false, required: false
            },
        ]
    },
    Propertorship: {
        "Details of Proprietorship Firm": [
            {
                label: "Proprietorship Firm Name",
                name: "name",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Proprietorship Contact Number",
                name: "phone",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Proprietorship Email",
                name: "email",
                type: "text",
                dropdown: false, required: true
            },
            {
                label: "Income Range",
                name: "income",
                type: "text", //dropdowns
                dropdown: true, required: true,
                value: ["₹ 5L-10L", "₹ 10L-25L", "₹ 25L-1Cr", "> ₹ 1 Cr"]
            }
        ],
        "Details of Owner & Owner related docs": [
            {
                name: "name",
                type: "text",
                label: "Name",
                dropdown: false, required: true
            },
            {
                type:"radio",
                name:"rel_type",
                dropdown: false, required: true
            },
            {
                name: "father",
                type: "text",
                label: "Father/Spouse Name",
                dropdown: false, required: true
            },
            {
                name: "dob",
                type: "date",
                label: "Date of Birth",
                dropdown: false, required: true
            },
            {
                name: "birth_place",
                type: "text",
                label: "Place of Birth",
                dropdown: false, required: true
            },
            {
                name: "gender",
                type: "text",
                label: "Gender",
                dropdown: true, required: true,
                value: ["MALE", "FEMALE"]
            },
            {
                name: "email",
                type: "email",
                label: "Email ID",
                dropdown: false, required: true
            },
            {
                name: "phone",
                type: "number",
                label: "Phone Number",
                dropdown: false, required: true
            },
            {
                name: "politically_exposed",
                type: "text",
                label: "Politically exposed person (yes/no)",
                value: ["YES", "NO"],
                dropdown: true, required: true,
            },
            {
                name: "nom_name",
                type: "text",
                label: "Nominee Name",
                dropdown: false, required: true
            },
            {
                name: "nom_rel",
                type: "text",
                label: "Nominee Relationship",
                dropdown: false, required: true
            },
            {
                name: "doc_pan",
                type: "file",
                label: "Upload Pan Card of Owner",
                dropdown: false, 
                required: true
            },
            {
                name: "doc_aadhar",
                type: "file",
                label: "Upload Aadhar Card of Owner",
                dropdown: false, required: true
            },
        ],
        "Proprietorship related documents": [
            {
                name: "doc_gst_certificate",
                type: "file",
                label: "GST Certificate",
                dropdown: false, required: true
            },
            {
                name: "doc_cancelled_cheque",
                type: "file",
                label: "Cancelled Cheque ",
                dropdown: false, required: true
            }
        ]
    },
    Individual: {
        "Details of Individual": [
            {
                name: "name",
                type: "text",
                label: "Name",
                dropdown: false, required: true
            },
            {
                type:"radio",
                name:"rel_type",
                dropdown: false, required: true
            },
            {
                name: "father",
                type: "text",
                label: "Father/Spouse Name",
                dropdown: false, required: true
            },
            {
                name: "dob",
                type: "date",
                label: "Date of Birth",
                dropdown: false, required: true
            },
            {
                name: "birth_place",
                type: "text",
                label: "Place of Birth",
                dropdown: false, required: true
            },
            {
                name: "gender",
                type: "text",
                label: "Gender",
                dropdown: true, required: true,
                value: ["MALE", "FEMALE"]
            },
            {
                name: "email",
                type: "email",
                label: "Email ID",
                dropdown: false, required: true
            },
            {
                name: "phone",
                type: "number",
                label: "Phone Number",
                dropdown: false, required: true
            },
            {
                name: "income",
                type: "text", //dropdowns
                label: "Income Range",
                dropdown: true, required: true,
                value: ["₹ 5L-10L", "₹ 10L-25L", "₹ 25L-1Cr", "> ₹ 1 Cr"]
            },
            {
                name: "politically_exposed",
                type: "text",
                dropdown: true, required: true,
                value: ["YES", "NO"],
                label: "Politically exposed person (yes/no",
            },
            {
                name: "nom_name",
                type: "text",
                label: "Nominee Name",
                dropdown: false, required: true
            },
            {
                name: "nom_rel",
                type: "text",
                label: "Nominee Relationship",
                dropdown: false, required: true
            },
            {
                name: "doc_pan",
                type: "file",
                label: "PAN",
                dropdown: false, required: true
            },
            {
                name: "doc_aadhar",
                type: "file",
                label: "Aadhar",
                dropdown: false, required: true
            },
        ],
        "Individual related documents": [
            {
                name: "doc_cancelled_cheque",
                type: "file",
                label: "Cancelled Cheque",
                dropdown: false, required: true
            }
        ]
    },
    Individual_Test:{}
}

export { InputFields }