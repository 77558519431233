import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  Box
} from "@mui/material";

function CompanyDocument({ accessToken,handleBack, heading, Fields, handleNext, user, capturedData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState<any>({});

  const fileChangeHandler = event => {
 
    const { name, files } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files[0]
    }))
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/companydocs`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': `multipart/form-data`
          },
          params: { id: capturedData.doc_id }
        }
      )
      .then(res => {
        const { data } = res;
    
        if (!data.succ) {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(data.message);
          return;
        }
        setIsLoading(false);
        setIsSuccess(true);
        setMessage(data.message);
        setTimeout(() => {
          handleNext();
        }, 2000);
      })
      .catch(error => {
        console.log("error: ",error);
        setIsLoading(false);
        setIsFailure(true);
        setMessage(error?.response?.data?.message);
        return;
      });
  };

  return (
    <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Typography variant="subtitle1">{heading}</Typography>
          {Fields?.map((ele, id) => {
            return <div key={id} >
              <TextField
                label={ele.label}
                onChange={fileChangeHandler}
                type={ele.type}
                name={ele.name}
                required={ele.required}
                variant="outlined"
                margin="normal"
                fullWidth
                focused={ele.type == "date" || ele.type == "file"}
                select={ele.dropdown}
                error={!!validationErrors[ele.name]} // Check if the field has an error
                helperText={validationErrors[ele.name]||ele.helperText} /// Display the error message
              >
                {ele.dropdown && ele.value.map((val) =>
                  <MenuItem value={val} >{val}</MenuItem>
                )}
              </TextField>
            </div>
          })}

          {/* <TextField
            label="Upload Company Pan Card"
            onChange={fileChangeHandler}
            name="comp_pancard"
            type="file"
            required
            variant="outlined"
            hidden
            focused
            margin="normal"
            helperText="Only PDF format allowed"
            fullWidth
          ></TextField>
          <TextField
            label="Upload Certificate of Incorporation"
            onChange={fileChangeHandler}
            name="certificate_incorporation"
            type="file"
            required
            variant="outlined"
            hidden
            focused
            margin="normal"
            helperText="Only PDF format allowed"
            fullWidth
          ></TextField>
          <TextField
            label="Upload Articles of Association"
            onChange={fileChangeHandler}
            name="article_association"
            type="file"
            required
            variant="outlined"
            hidden
            focused
            margin="normal"
            helperText="Only PDF format allowed"
            fullWidth
          ></TextField>
          <TextField
            label="Upload Memorandum of Association"
            onChange={fileChangeHandler}
            name="memorandum_association"
            type="file"
            required
            variant="outlined"
            hidden
            focused
            helperText="Only PDF format allowed"
            margin="normal"
            fullWidth
          ></TextField>
          <TextField
            label="Upload Balance Sheet & P&L for FY'21"
            onChange={fileChangeHandler}
            name="balancesheet21"
            type="file"
            required
            variant="outlined"
            helperText="Only PDF format allowed"
            hidden
            focused
            margin="normal"
            fullWidth
          ></TextField>
          <TextField
            label="Upload Balance Sheet & P&L for FY'22"
            onChange={fileChangeHandler}
            name="balancesheet22"
            type="file"
            required
            variant="outlined"
            helperText="Only PDF format allowed"
            hidden
            focused
            margin="normal"
            fullWidth
          ></TextField>
          <TextField
            label="Upload Cancelled Cheque/ Bank Statement"
            onChange={fileChangeHandler}
            name="cancel_cheque"
            type="file"
            required
            variant="outlined"
            helperText="Only PDF format allowed"
            hidden
            focused
            margin="normal"
            fullWidth
          ></TextField> */}
          <Box sx={{display:"flex",flex:"row",gap:4}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {
                "Back"
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onSubmit={handleSubmit}
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </form>
      </CardContent>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={() => setIsFailure(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default CompanyDocument;
