import { FormatDecimal } from "../../../utils/formatNumber"

const RecommendationLogic = (InvestorType: any, TimeHorizo: any, Amount: any, setTransactionCount: any) => {
    if (InvestorType == 1) {
        if (TimeHorizo == '0 - 3 Months') {
            if (Amount <= 1000000) {
                setTransactionCount([
                    {
                        Funds: "RMF",
                        Scheme: "ONGPGR",
                        view_amount: FormatDecimal(0.2 * Number(Amount)),
                        Amount: FormatDecimal(0.2 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.8 * Number(Amount)),
                        Amount: FormatDecimal(0.8 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Amount > 1000000 && Amount <= 50000000) {
                setTransactionCount([
                    {
                        Funds: "RMF",
                        Scheme: "ONGPGR",
                        view_amount: FormatDecimal(0.2 * Number(Amount)),
                        Amount: FormatDecimal(0.2 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.4 * Number(Amount)),
                        Amount: FormatDecimal(0.4 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "H",
                        Scheme: "HLFGN",
                        view_amount: FormatDecimal(0.4 * Number(Amount)),
                        Amount: FormatDecimal(0.4 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Amount > 5000000) {
                setTransactionCount([
                    {
                        Funds: "RMF",
                        Scheme: "ONGPGR",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "H",
                        Scheme: "HLFGN",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                ])
            }
        } else if (TimeHorizo == "3 - 12 Months") {
            if (Number(Amount) < 1000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.30 * Number(Amount)),
                        Amount: FormatDecimal(0.30 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "P",
                        Scheme: "1746",
                        view_amount: FormatDecimal(0.50 * Number(Amount)),
                        Amount: FormatDecimal(0.50 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "AFGPGR",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Number(Amount) > 1000000 && Number(Amount) <= 5000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "P",
                        Scheme: "1746",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "AFGPGR",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "LPIGGR",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Number(Amount) > 5000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "H",
                        Scheme: "HLFGN",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },

                    {
                        Funds: "P",
                        Scheme: "1746",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "AFGPGR",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "LPIGGR",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                ])
            }
        }
    } else if (InvestorType != 1) {
        if (TimeHorizo == '0 - 3 Months') {
            if (Amount <= 1000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.2 * Number(Amount)),
                        Amount: FormatDecimal(0.2 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "57G",
                        view_amount: FormatDecimal(0.8 * Number(Amount)),
                        Amount: FormatDecimal(0.8 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Amount > 1000000 && Amount <= 50000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.2 * Number(Amount)),
                        Amount: FormatDecimal(0.2 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "57G",
                        view_amount: FormatDecimal(0.4 * Number(Amount)),
                        Amount: FormatDecimal(0.4 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "H",
                        Scheme: "HLFGN",
                        view_amount: FormatDecimal(0.4 * Number(Amount)),
                        Amount: FormatDecimal(0.4 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Amount > 5000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "57G",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "H",
                        Scheme: "HLFGN",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                ])
            }
        } else if (TimeHorizo != '0 - 3 Months') {
            if (Number(Amount) < 1000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.30 * Number(Amount)),
                        Amount: FormatDecimal(0.30 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "P",
                        Scheme: "1746",
                        view_amount: FormatDecimal(0.50 * Number(Amount)),
                        Amount: FormatDecimal(0.50 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "AFGPGR",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Number(Amount) > 1000000 && Number(Amount) <= 5000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "P",
                        Scheme: "1746",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "AFGPGR",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "LPIGGR",
                        view_amount: FormatDecimal(0.25 * Number(Amount)),
                        Amount: FormatDecimal(0.25 * Number(Amount)),
                        folio: ""
                    },
                ])
            } else if (Number(Amount) > 5000000) {
                setTransactionCount([
                    {
                        Funds: "L",
                        Scheme: "72SG",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "H",
                        Scheme: "HLFGN",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },

                    {
                        Funds: "P",
                        Scheme: "1746",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "AFGPGR",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                    {
                        Funds: "RMF",
                        Scheme: "LPIGGR",
                        view_amount: FormatDecimal(0.20 * Number(Amount)),
                        Amount: FormatDecimal(0.20 * Number(Amount)),
                        folio: ""
                    },
                ])
            }
        }
    } else {
        throw new Error("Invalid Inputs");
    }


}

export { RecommendationLogic }
// const Data = [
//     {
//         '0 - 3 Months': (function () {
//             if (Amount <= 1000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.2 * Number(Amount)),
//                         Amount: FormatDecimal(0.2 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "57G",
//                         view_amount: FormatDecimal(0.8 * Number(Amount)),
//                         Amount: FormatDecimal(0.8 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Amount > 1000000 && Amount <= 50000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.2 * Number(Amount)),
//                         Amount: FormatDecimal(0.2 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "57G",
//                         view_amount: FormatDecimal(0.4 * Number(Amount)),
//                         Amount: FormatDecimal(0.4 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "H",
//                         Scheme: "HDFC",
//                         view_amount: FormatDecimal(0.4 * Number(Amount)),
//                         Amount: FormatDecimal(0.4 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Amount > 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "57G",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "H",
//                         Scheme: "HDFC",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "72SG",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else {
//                 const data = {
//                     Funds: "",
//                     Scheme: "",
//                     Amount: "",
//                     folio: "",
//                     view_amount: "",
//                 };
//                 setTransactionCount([data]);
//             }
//         })(),
//         "3 - 12 Months": (function () {
//             if (Number(Amount) < 1000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.30 * Number(Amount)),
//                         Amount: FormatDecimal(0.30 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.50 * Number(Amount)),
//                         Amount: FormatDecimal(0.50 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Number(Amount) > 1000000 && Number(Amount) <= 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "LPIGGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Number(Amount) > 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "H",
//                         Scheme: "HDFC",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },

//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "LPIGGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else {
//                 const data = {
//                     Funds: "",
//                     Scheme: "",
//                     Amount: "",
//                     folio: "",
//                     view_amount: "",
//                 };
//                 setTransactionCount([data]);
//             }
//         })()
//     },
//     {
//         "0 - 3 Months": (function () {
//             if (Amount <= 1000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.2 * Number(Amount)),
//                         Amount: FormatDecimal(0.2 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "57G",
//                         view_amount: FormatDecimal(0.8 * Number(Amount)),
//                         Amount: FormatDecimal(0.8 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Amount > 1000000 && Amount <= 50000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.2 * Number(Amount)),
//                         Amount: FormatDecimal(0.2 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "57G",
//                         view_amount: FormatDecimal(0.4 * Number(Amount)),
//                         Amount: FormatDecimal(0.4 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "H",
//                         Scheme: "HDFC",
//                         view_amount: FormatDecimal(0.4 * Number(Amount)),
//                         Amount: FormatDecimal(0.4 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Amount > 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "57G",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "H",
//                         Scheme: "HDFC",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "L",
//                         Scheme: "72SG",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else {
//                 const data = {
//                     Funds: "",
//                     Scheme: "",
//                     Amount: "",
//                     folio: "",
//                     view_amount: "",
//                 };
//                 setTransactionCount([data]);
//             }
//         })(),
//         "3 - 12 Months": (function () {
//             if (Number(Amount) < 1000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.30 * Number(Amount)),
//                         Amount: FormatDecimal(0.30 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.50 * Number(Amount)),
//                         Amount: FormatDecimal(0.50 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Number(Amount) > 1000000 && Number(Amount) <= 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "LPIGGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Number(Amount) > 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "H",
//                         Scheme: "HDFC",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },

//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "LPIGGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else {
//                 const data = {
//                     Funds: "",
//                     Scheme: "",
//                     Amount: "",
//                     folio: "",
//                     view_amount: "",
//                 };
//                 setTransactionCount([data]);
//             }
//         })(),
//         "More than 1 year": (function () {
//             if (Number(Amount) < 1000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.30 * Number(Amount)),
//                         Amount: FormatDecimal(0.30 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.80 * Number(Amount)),
//                         Amount: FormatDecimal(0.80 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Number(Amount) > 1000000 && Number(Amount) <= 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "LPIGGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else if (Number(Amount) > 5000000) {
//                 setTransactionCount([
//                     {
//                         Funds: "RMF",
//                         Scheme: "ONGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "H",
//                         Scheme: "HDFC",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },

//                     {
//                         Funds: "P",
//                         Scheme: "1746",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "AFGPGR",
//                         view_amount: FormatDecimal(0.20 * Number(Amount)),
//                         Amount: FormatDecimal(0.20 * Number(Amount)),
//                         folio: ""
//                     },
//                     {
//                         Funds: "RMF",
//                         Scheme: "LPIGGR",
//                         view_amount: FormatDecimal(0.25 * Number(Amount)),
//                         Amount: FormatDecimal(0.25 * Number(Amount)),
//                         folio: ""
//                     },
//                 ])
//             } else {
//                 const data = {
//                     Funds: "",
//                     Scheme: "",
//                     Amount: "",
//                     folio: "",
//                     view_amount: "",
//                 };
//                 setTransactionCount([data]);
//             }
//         })()
//     }
// ]
// if (InvestorType == 1) {
//     return Data[1][TimeHorizo];
// } else {
//     return Data[0][TimeHorizo];
// }