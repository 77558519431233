import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const InvestTabs: React.FC = () => {
  const navigate = useNavigate();
  const storeState = {
    ACTIVEINVETOR: {
      folio: {
        Folio: "11", // Example folio, replace with actual data
      },
    },
  };

  const cardStyles = {
    background: "linear-gradient(180deg, #60C5FF 0%, #007BBD 100%)",
    color: "white",
    borderRadius: "20px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "270px",
    maxWidth: "500px",
  };

  return (
    <Box sx={{ width: "100%", mt: "150px" }}>
      <Grid container spacing={2} justifyContent="center">
        {/* Card for Mutual Funds */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Stack sx={{ width: "80%" }}>
                  <Typography variant="h6">Invest in</Typography>
                  <Typography variant="h4" fontWeight="bold">
                    Mutual Funds
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h6" sx={{ marginTop: "10px" }}>
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      For Market Linked Returns
                    </span>
                  </Typography>
                </Stack>
              </Grid>

              <Button
                // variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "#007BBD",
                  marginTop: "60px",
                  width: "100%",
                  fontWeight: "700",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#007BBD",
                  },
                }}
                onClick={() =>
                  navigate(
                    `/dashboardAdmin/nse/multi/order/${storeState.ACTIVEINVETOR.folio.Folio}`,
                    { state: { status: 0 } }
                  )
                }
              >
                Invest
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Gold */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Stack sx={{ width: "80%" }}>
                  <Typography variant="h6">Invest in</Typography>
                  <Typography variant="h4" fontWeight="bold">
                    Gold
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h6" sx={{ marginTop: "10px" }}>
                    {/* <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      For Market Linked Returns
                    </span> */}
                  </Typography>
                </Stack>
              </Grid>

              <Button
                // variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "#007BBD",
                  marginTop: "60px",
                  width: "100%",
                  fontWeight: "700",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#007BBD",
                  },
                }}
                onClick={() =>
                  navigate(`/dashboardAdmin/goldInvesting`, {
                    state: { status: 0 },
                  })
                }
              >
                Invest
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Fixed Deposit */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "40px",
                }}
              >
                <Stack>
                  <Typography variant="h6">Invest in</Typography>
                  <Typography variant="h4" fontWeight="bold">
                    Fixed Deposit
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h6" sx={{ marginTop: "10px" }}>
                    Gains up to{" "}
                    <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                      9.1%
                    </span>
                  </Typography>
                </Stack>
              </Grid>

              <Button
                sx={{
                  backgroundColor: "white",
                  color: "#007BBD",
                  marginTop: "60px",
                  width: "100%",
                  fontWeight: "700",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#007BBD",
                  },
                }}
                onClick={() =>
                  navigate(
                    `/dashboardAdmin/nse/fd/order/${storeState.ACTIVEINVETOR.folio.Folio}`,
                    { state: { status: 0 } }
                  )
                }
              >
                Invest
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestTabs;
