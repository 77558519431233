import {
    Grid,
    Box,
    Tab,
    Alert,
    Snackbar
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@material-ui/lab'
import TransactionDatewise from './TransactionDatewise';
import 'rsuite/dist/rsuite.min.css';
import React, { useState } from 'react';
import TransactionPending from './TransactionPending';
import { useLocation } from 'react-router-dom';
import TransactionReports from './TransactionReports';

export default function TransactionNSE({accessToken},props:any) {
    const { state } = useLocation();
    const [msg, setMsg] = useState(state == "2" ? true : false);
    const [value, setValue] = React.useState<any>(state == "2" ? state : "1");
    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const CloseHandle = ()=>{
        setMsg(false);
    }
   
    return <Grid container spacing={2} xs>
        <Box>
            <TabContext value={value}  >
                <Box sx={{ width: "85vw", borderBottom: 2, margin: "-19px 0 0 16px", borderColor: 'divider', bgcolor: '#2f85d7', color: 'white' }}>
                    <TabList onChange={(event, newValue) => handleChange(newValue)} TabIndicatorProps={{
                        style: { backgroundColor: 'white', color: 'white' },
                    }}>
                        <Tab style={{ color: 'white', fontWeight: 650 }} label="Transactions" value="1" />
                        <Tab style={{ color: 'white', fontWeight: 650 }} label="Pending Transactions" value="2" />
                        <Tab style={{ color: 'white', fontWeight: 650 }} label="Transactions Report" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <TransactionDatewise accessToken={accessToken} />
                </TabPanel>
                <TabPanel value="2">
                    <TransactionPending accessToken={accessToken} />
                </TabPanel>
                <TabPanel value="3">
                    <TransactionReports accessToken={accessToken} />
                </TabPanel>
            </TabContext>
            <Snackbar
                open={msg}
                autoHideDuration={4000}
                onClose={CloseHandle}
            >
                <Alert severity="warning">
                    Please fill UTR No. and transfer date to finish transaction</Alert>
            </Snackbar>
        </Box>
    </Grid>
}
