import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Divider,
  Box,
  Stack,
} from "@mui/material";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ChartOptions } from "chart.js";
import IssuerHeader from "../CreateFdOrder/IssuerHeader";

ChartJS.register(ChartDataLabels);

interface IssuerDetailProps {
  accessToken: string;
}

interface Scheme {
  id: string;
  issuer_id: string;
  interest_rate: number;
  min_amount: number;
  max_amount: number;
  lock_in_period: number;
  min_tenure: number;
  max_tenure: number;
  interest_payout_frequency: string;
}

const IssuerDetail = ({ accessToken }: IssuerDetailProps) => {
  const { id } = useParams<{ id: string }>();
  const [schemes, setSchemes] = useState<Scheme[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedScheme, setSelectedScheme] = useState<Scheme | null>(null);
  const [investmentAmount, setInvestmentAmount] = useState<number>(0);
  const [maturityAmount, setMaturityAmount] = useState<number>(0);
  const [interestAmount, setInterestAmount] = useState<number>(0);
  const [interestYield, setInterestYield] = useState<number>(0);
  const [isSeniorCitizen, setIsSeniorCitizen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [amountError, setAmountError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [interestPayoutFrequency, setInterestPayoutFrequency] =
    useState<string>("maturity");
  const [tenure, setTenure] = useState<number | undefined>(undefined);
  const [womanSpecial, setWomanSpecial] = useState<boolean>(false);
  const [tenureOptions, setTenureOptions] = useState<number[]>([]); 
  const [tenureError, setTenureError] = useState<string | null>(null);
  const [noResults, setNoResults] = useState(false);


  const fetchSchemes = async () => {
    try {
      const response = await axios.get(
        `http://localhost:8081/v1/user/fixeddeposit/getschemes?issuer_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const fetchedSchemes = response.data.schemes;
      setSchemes(fetchedSchemes);
  
      const uniqueTenures = fetchedSchemes
        .map((scheme) => scheme.min_tenure)
        .filter((value, index, self) => self.indexOf(value) === index);
  
      setTenureOptions(uniqueTenures);
    } catch (err) {
      console.error("Error fetching schemes:", err);
      setError("Failed to fetch schemes");
    } finally {
      setLoading(false);
    }
  };

  console.log(tenure)
  

  const handleBuyNow = () => {
    if (selectedScheme && investmentAmount) {
      navigate(`/dashboardAdmin/issuer/${id}/purchase/${selectedScheme.id}`, {
        state: {
          amount: investmentAmount,
          interestYield: interestYield,
          tenure:tenure,
          interestPayoutFrequency:interestPayoutFrequency
        },
      });
    } else {
      console.error("No scheme or amount selected");
    }
  };

  useEffect(() => {
    fetchSchemes();
  }, [id]);


  useEffect(() => {
    const calculateReturns = async () => {
      if (!amountError && !tenureError && investmentAmount && tenure) {
        const dataToSend = {
          amount: investmentAmount,
          scheme_category: isSeniorCitizen ? "senior_citizen" : "general",
          interest_payout_frequency: interestPayoutFrequency,
          tenure: tenure,
          issuer_id: id,
          women_special: womanSpecial,
        };
  
        try {
          const response = await axios.post(
            "http://localhost:8081/v1/user/fixeddeposit/calculatefixeddeposit",
            dataToSend,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
  
          if (response.status === 200) {
            const { results } = response.data;
            setMaturityAmount(results[0].maturity_amount);
            setInterestAmount(results[0].interest_amount);
            setInterestYield(results[0].interest_yield);

            // Store the selected scheme
            const scheme = schemes.find((s) => s.id === results[0].scheme_id);
            setSelectedScheme(scheme || null);
          } else {
            console.error("Error in calculation:", response.statusText);
          }
        } catch (err) {
          console.error("Error calling the API:", err);
        }
      }
    };
  }, [tenure]);

 

  const calculateReturns = async () => {
    if (!amountError && !tenureError && investmentAmount && tenure) {
      const dataToSend = {
        amount: investmentAmount,
        scheme_category: isSeniorCitizen ? "senior_citizen" : "general",
        interest_payout_frequency: interestPayoutFrequency,
        tenure: tenure,
        issuer_id: id,
        women_special: womanSpecial,
      };

      try {
        const response = await axios.post(
          "http://localhost:8081/v1/user/fixeddeposit/calculatefixeddeposit",
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.data.results.length === 0) {
          setNoResults(true); // Set state to indicate no results found
          return;
        }

        if (response.status === 200) {
          const { results } = response.data;
          setMaturityAmount(results[0].maturity_amount);
          setInterestAmount(results[0].interest_amount);
          setInterestYield(results[0].interest_yield);
          const scheme = schemes.find((s) => s.id === results[0].scheme_id);
          setSelectedScheme(scheme || null);
          setNoResults(false);
        } else {
          console.error("Error in calculation:", response.statusText);
        }
      } catch (err) {
        console.error("Error calling the API:", err);
      }
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!schemes.length) {
    return <Typography>No schemes found</Typography>;
  }

  const data = {
    labels: ["Bajaj FD", "HDFC FD", "SBI FD", "Savings A/c"],
    datasets: [
      {
        label: "Interest rate %",
        data: [8.25, 7.4, 7.1, 3],
        backgroundColor: ["#80ed99", "#b8b8ff", "#b8b8ff", "#b8b8ff"],
        borderRadius: 20,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Interest Rate Comparison",
      },
      datalabels: {
        color: "#3186d7", 
        anchor: "end",
        align: "top", 
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
  };

  
  return (
    <>
      <Stack>
        <IssuerHeader accessToken={accessToken} />
      </Stack>
      <Grid container spacing={4} sx={{ padding: "2rem" }}>
         {/* Left Section - Scheme Graph */}
         <Grid item xs={12} md={8}>
          <Card sx={{ padding: "1.5rem" }}>
            <Typography variant="h5" gutterBottom>
              Interest Rate Comparison
            </Typography>
            <Bar data={data} options={options} />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ padding: "1.5rem" }}>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Calculate Returns
              </Typography>
              <Divider sx={{ marginBottom: "1.5rem" }} />

              <TextField
                fullWidth
                label="Investment Amount"
                type="number"
                sx={{marginBottom:'1.5rem', borderRadius:'10px'}}
                value={investmentAmount === 0 ? "" : investmentAmount}
                onChange={(e) => setInvestmentAmount(Number(e.target.value))}
                error={!!amountError}
                helperText={amountError}
                InputProps={{
                  endAdornment: <Typography variant="caption">₹</Typography>,
                }}
              />

              <FormControl fullWidth sx={{ marginBottom: "1.5rem" }}>
                <InputLabel id="interest-payout-frequency-label">
                  Interest Payout Frequency
                </InputLabel>
                <Select
                  labelId="interest-payout-frequency-label"
                  value={interestPayoutFrequency}
                  sx={{borderRadius:'10px'}}
                  onChange={(e) => setInterestPayoutFrequency(e.target.value)}
                >
                  {[
                    "monthly",
                    "quarterly",
                    "half_yearly",
                    "yearly",
                    "maturity",
                  ].map((frequency) => (
                    <MenuItem key={frequency} value={frequency}>
                      {frequency.replace(/_/g, " ")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ marginBottom: "1.5rem" }}>
                <InputLabel id="tenure-label">Tenure (months)</InputLabel>
                <Select
                sx={{borderRadius:'10px'}}
                  labelId="tenure-label"
                  value={tenure || ""}
                  onChange={(e) => setTenure(Number(e.target.value))}
                  error={!!amountError}
                >
                  {tenureOptions.map((minTenure) => (
                    <MenuItem key={minTenure} value={minTenure}>
                      {minTenure}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSeniorCitizen}
                    onChange={(e) => setIsSeniorCitizen(e.target.checked)}
                  />
                }
                label="I am a senior citizen"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={womanSpecial}
                    onChange={(e) => setWomanSpecial(e.target.checked)}
                  />
                }
                label="I am a woman"
              />

              <Stack sx={{display:'flex', flexDirection:'row', gap:'10px',}}>

              <Button
                // variant="contained"
                sx={{width:'70%', bgcolor: "#4ad66d",color:'white', fontWeight:'600', border:'1px solid', borderRadius:'10px',   "&:hover": {
                  background: "white",
                  color: "#4ad66d",
                  borderColor: "#4ad66d",
                  border: "1px solid",
                },}}
                onClick={handleBuyNow}
                disabled={!selectedScheme}
              >
                Buy Now
              </Button>

              <Button
                // variant="contained"
            sx={{width:'30%', bgcolor: "#5465ff",color:'white', fontWeight:'600', border:'1px solid', borderRadius:'10px',   "&:hover": {
              background: "white",
              color: "#5465ff",
              borderColor: "#5465ff",
              border: "1px solid",
            },}}
                onClick={calculateReturns}
                disabled={!!amountError || !!tenureError}
              >
                Calculate
              </Button>
              </Stack>


              {noResults && (
      <Typography variant="h6" sx={{ marginTop: "1rem", color: "red" }}>
        Not found any schemes, try other schemes.
      </Typography>
    )}

    {maturityAmount > 0 && !noResults && (
      <Typography variant="h6" sx={{ marginTop: "1rem" }}>
        Maturity Amount:{" "}
        <span style={{ color: "green" }}>
          ₹ {maturityAmount.toFixed(2)}
        </span>
      </Typography>
    )}
    {interestAmount > 0 && !noResults && (
      <Typography variant="h6" sx={{ marginTop: "1rem" }}>
        Interest Amount:{" "}
        <span style={{ color: "green" }}>
          ₹ {interestAmount.toFixed(2)}
        </span>
      </Typography>
    )}
    {interestYield > 0 && !noResults && (
      <Typography variant="h6" sx={{ marginTop: "1rem" }}>
        Interest Yield:{" "}
        <span style={{ color: "green" }}>
          {interestYield.toFixed(2)}%
        </span>
      </Typography>
    )}
            </CardContent>
          </Card>
        </Grid>

        <Stack>
          <Box sx={{ padding: 2, marginLeft: "30px" }}>
            <Grid
              container
              spacing={20}
              justifyContent="center"
              sx={{ alignItems: "center" }}
            >
              <Grid item textAlign="center">
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    color: "#656565",
                  }}
                >
                  Tenure
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  12 - 60 Months
                </Typography>
              </Grid>
              <Grid item textAlign="center">
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    color: "#656565",
                  }}
                >
                  Min. Deposit
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  RS 5000
                </Typography>
              </Grid>
              <Grid item textAlign="center">
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    color: "#656565",
                  }}
                >
                  Max Deposit
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  RS 10,00,00,000
                </Typography>
              </Grid>
              <Grid item textAlign="center">
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    color: "#656565",
                  }}
                >
                  Lock-in
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  3 Months (0.3 Y)
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

export default IssuerDetail;
