import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Box
} from "@mui/material";
import { BankName } from "../../../../../Data/data";

function Individual_bank({ accessToken, handleBack, handleNext, user, capturedData, setCapturedData}) {
    const [validationErrors, setValidationErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [message, setMessage] = useState("");
    const [formData, SetFormData] = useState<any>({...capturedData,...capturedData.investorType_details})
  
    const handleChange = event => {
      let { name, value } = event.target;
      SetFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (event: any) => {
      event.preventDefault();
      if(formData.acc_no != formData.account_number_confirm){
        setIsFailure(true);
        setMessage("Account number not match !");
        setValidationErrors((prev)=>({
          ...prev,
          account_number_confirm :"Account number not match !"
        }));
        return;
      }

      setCapturedData((prev)=>({
        ...prev,...formData
      }))
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/bankdetails`,
          { ...formData },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(res => {
          const { data } = res;
          if (!data.succ) {
            setIsLoading(false);
            setIsFailure(true);
            setMessage(data.message);
            return;
          }
          setCapturedData((prev) => ({
            ...prev,
            ["doc_id"]: data.id
          }))
          setIsSuccess(true);
          setMessage(data.message);
          setIsLoading(false);
          setTimeout(() => {
            handleNext();
          }, 2000);
        })
        .catch(error => {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(error.message);
          return;
        });
    };
    const heading = "Bank Details";
    const Fields = [
        {
            name: "bank_name",
            type: "text",
            label: "Bank Name",
            dropdown: true, required: true,
            value:BankName
        },
        {
            name: "acc_type",
            type: "text",
            label: "Account Type",
            dropdown: true, required: true,
            value:[{code:"SB",title:"SAVING"},{code:"CA",title:"CURRENT"}]
        },
        {
            name: "acc_no",
            type: "phone",
            label: "Account Number",
            dropdown: false, required: true,
            value:[]
        },
        {
            name: "account_number_confirm",
            type: "phone",
            label: "Re-enter Account Number",
            dropdown: false, required: true,
            value:[]
        },
        {
            name: "ifsc_code",
            type: "text",
            label: "IFSC code",
            dropdown: false, required: true,
            value:[]
        },
        {
            name: "branch_name",
            type: "text",
            label: "Branch Name",
            dropdown: false, required: true,
            value:[]
        },
        {
            name: "branch_addr1",
            type: "text",
            label: "Branch Address",
            dropdown: false, required: true,
            value:[]
        },
    ]

  return (
    <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Typography variant="subtitle1">{heading}</Typography>
          {Fields?.map((ele, idx) => {
            return <div key={idx} >
              <TextField
                label={ele.label}
                onChange={handleChange}
                type={ele.type}
                value={formData[ele.name]}
                name={ele.name}
                required={ele.required}
                variant="outlined"
                margin="normal"
                fullWidth
                focused={ele.type == "date"}
                select={ele.dropdown}
                error={!!validationErrors[ele.name]} // Check if the field has an error
                helperText={validationErrors[ele.name]} /// Display the error message
              >
                {ele.dropdown &&ele.value.map((val) =>
                  <MenuItem value={val.code} >{val.title}</MenuItem>
                )}
              </TextField>
            </div>
          })}
          <Box sx={{display:"flex",flex:"row",gap:4}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {
                "Back"
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </form>
      </CardContent>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={() => setIsFailure(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>
    </Card>
  )
}
export default Individual_bank