import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useAppContext } from "../../../Store/AppContext";

const AddNewBank = ({
  isOpenBank,
  setIsOpenBank,
  bank,
  accessToken,
  setIsUpdated,
  setSelectedBank,
  setError,
  setSuccess,
  setMsg,
}: any) => {
  const [storeState, dispatch] = useAppContext();
  const [account, setAccount] = useState<string>(
    bank ? bank?.accountNumber : ""
  );
  const [accountName, setAccountName] = useState<string>(
    bank ? bank?.accountName : ""
  );
  const [bankName, setBankName] = useState<string>(bank ? bank?.bankName : "");
  const [ifac, setIfac] = useState<string>(bank ? bank.ifscCode : "");
  const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //   useEffect(() => {
  //     if (bank) {
  //     setAccount(bank ? )
  //     }
  //   }, []);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setIsOpenBank(false);
    setSelectedBank(null);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    let bankObj: any = {
      bankName: bankName,
      accountNumber: account,
      accountName: accountName,
      ifscCode: ifac,
    };

    if (bank?.bank_id) {
      bankObj.bank_id = bank?.bank_id;
    }

    console.log("Bank Object ->", bankObj);

    setIsLoading(true);

    if (bank?.bank_id) {
      try {
        let response = await axios.put(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/bank`,
          bankObj,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        console.log("Bank update response ->", response.data);
        if (response.data.succ) {
          setSuccess(true);
          setMsg(response.data.message);
          setIsUpdated(true);
          handleClose();
        } else {
          console.log("Account Details Error ->", response.data.message);
          setError(true);
          setMsg(response.data.message);
        }
      } catch (error) {
        console.log("bank Details Error ->", error);
      }
    } else {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/bank`,
          bankObj,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        console.log("Bank add response ->", response.data);
        if (response.data.succ) {
          setSuccess(true);
          setMsg(response.data.message);
          setIsUpdated(true);
          handleClose();
        } else {
          console.log("Account Details Error ->", response.data.message);
          setError(true);
          setMsg(response.data.message);
        }
      } catch (error) {
        console.log("bank Details Error ->", error);
      }
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        open={isOpenBank}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {bank?.bank_id ? "Update Bank Details" : "Add Bank Details"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Account Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Account Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Bank Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Ifac Code"
              variant="outlined"
              margin="normal"
              fullWidth
              value={ifac}
              onChange={(e) => setIfac(e.target.value)}
            />
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default AddNewBank;
