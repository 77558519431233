import React, { useState } from 'react'
import { Typography, Modal, Box } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  key: string,
  value: string,
) {
  return { key, value };
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 4,
  borderRadius: "5px",
  p: 3,

};

export default function BeneficiaryDetails({ isShowBeneficiary, setShowBeneficiary,iin }) {

  const rows = [
    createData('Beneficiary Name', 'NSE Clearing - New Mutual Fund Platform'),
    createData('Beneficiary Bank Name', 'HDFC Bank Ltd'),
    createData('Beneficiary Branch Name', 'Fort, Mumbai'),
    createData('Virtual Account No', `NSEMF2264077${iin}`),
    createData('Account Type', 'Current Account'),
    createData('IFSC Code', 'HDFC0000060'),
    createData('Pin code', '400001'),
  ];

  const handleClose = () => setShowBeneficiary(false);
  return (
    <Modal
      open={isShowBeneficiary}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ fontWeight: "600", fontSize: "17px", textAlign: "center" }}>
          Please add these beneficiary details for RTGS / NEFT payments (if not already added)
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 200 }} aria-label="simple table">
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography sx={{fontSize:"14px",fontWeight:600}}>
                      {row.key}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  )
}

/*


Please add these beneficiary details for RTGS / NEFT payments (if not already added)
Beneficiary Name
Beneficiary Bank Name
Beneficiary Branch Name
Virtual Account No
Account Type
IFSC Code
Pin code
NSE Clearing - New Mutual Fund Platform
HDFC Bank Ltd
Fort, Mumbai
NSEMF22640775013939187
Current Account
HDFC0000060
400001

*/