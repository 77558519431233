import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SideBar from "../Admin/SideBar";
import TopBar from "../Dashboard/TopBar";
import { relative } from "path";


const AdminLayout = (props: { children: JSX.Element, user: any }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isHumb, setHumb] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<string>("investing");
  const [name, setName] = useState(props.user.companyName)
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Grid container className="dashboard-container">
        <Grid item lg="auto" md={2.5} sx={!isHumb ? { position: "absolute", display: "block", zIndex: "12" } : { display: { xs: "none", md: "inherit" } }}>
          <SideBar
            selectedItem={selectedItem}
            drawerOpen={drawerOpen}
            setHumb={setHumb}
            setSelectedItem={setSelectedItem}
            setDrawerOpen={setDrawerOpen}
          />
        </Grid>
        <Grid
          item
          lg={true}
          md={9.5}
          xs={12}
          style={{ maxHeight: "100vh", overflowY: "scroll" }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <Grid >
              <TopBar
                companyName={name}
                isHumb={isHumb}
                setHumb={setHumb}
                accessToken={props.children.props.accessToken}
              />
            </Grid>
            <Grid xs={12} >{
              props.children
            }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

};

export default AdminLayout;
