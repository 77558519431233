import React, { useState, useEffect } from "react";
import { validateEmail, validatePassword } from "../../../utils/validators";
import {
  Box,
  CardContent,
  TextField,
  Card,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Snackbar,
  Grid,
} from "@mui/material";
import axios from "axios";
import loginImage from "../../../images/login-image.png";
import { useNavigate } from "react-router-dom";

const Sigin = ({ checking }) => {
  const [isLoading, setLoading] = useState(false);
  const [isOTPSent, setOTPSent] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isResend, setResend] = useState(false);
  const [timmer, setTimmer] = useState(60);
  const [isError, setError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  const [Msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [formData, setformData] = useState({
    email: "",
    otp: "",
    password: "",
  });

  useEffect(() => {
    let tokens = localStorage.getItem("tokens");
    try {
      if (tokens && !checking) window.location.href = "/dashboard/investing";
      else setIsLoggedIn(false);
    } catch (err) {
      localStorage.removeItem("tokens");
      setIsLoggedIn(false);
    }
  }, []);

  const OTPInterval = () => {
    const intervalId = setInterval(() => {
      setTimmer((timmer) => timmer - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  };

  const changeHandler = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name == "email") {
      if (!validateEmail(formData.email)) {
        setValidationErrors((prev) => ({
          ...prev,
          [name]: true,
        }));
        if (value === "") {
          setValidationErrors((prev) => ({
            ...prev,
            [name]: false,
          }));
        }
      }
    }
    setformData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const sendOTPHandler = async () => {
    setTimmer(60);
    setLoading(true);
    setResend(true);
    if (formData.email == "") {
      setError(true);
      setMsg("Email is required !");
      setLoading(false);
      setResend(false);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}v1/admin/nse/send-otp`, {
        email: formData.email,
      })
      .then((res) => {
        const { data } = res;
        if (!data.success) {
          setError(true);
          setMsg(data.message);
          setLoading(false);
          setResend(false);
          return;
        }
        setOTPSent(true);
        setSuccess(true);
        setMsg(data.message);
        setLoading(false);
        setResend(false);
        OTPInterval();
      })
      .catch((error) => {
        setError(true);
        setMsg(error.response.data.message);
        console.log(error);
        setLoading(false);
        setResend(false);
        return;
      });
  };
  const LoginHandler = () => {
    if (
      formData.email == "" &&
      (formData.password !== "" || formData.password !== "")
    ) {
      setError(true);
      setMsg("Please,Entere required inputs");
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/admin/nse/login`,
        {
          email: formData.email,
          password: formData.password,
        },
        {
          headers: {
            device: "web",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSuccess(true);
          setMsg(response.data.message);
          if ("token" in response.data.detail.member.token)
            localStorage.setItem(
              "tokens",
              JSON.stringify({
                accessToken: response.data.detail.member.token.token,
                refreshToken:
                  response.data.detail.member.token.refreshToken.refresh,
              })
            );
          else
            localStorage.setItem(
              "tokens",
              JSON.stringify({
                accessToken:
                  response.data.detail.member.token.updateToken.token,
                refreshToken:
                  response.data.detail.member.token.refreshToken.refresh,
              })
            );

          setTimeout(function () {
            window.location.href = "/dashboard/investing";
          }, 1000);
          //navigate("/dashboard/investing")
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
          console.log("that");
          setMsg(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setMsg(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Grid
      container
      sx={{
        minHeight:"100vh",
        justifyContent: "center",
        backgroundColor:"#3599cc",
      }}
    >
      <Grid item sm={12} md={6}>
      <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            p:2
          }}
        >
          <Typography
            sx={{
              fontFamily: "logoFont",
              fontSize: "2rem",
              fontWeight: "500",
            }}
          >
            <span style={{ fontFamily: "logoFont",color:"white" }}>KLARFIN</span>
          </Typography>
        </Box>
        <Card
          sx={{
            width: { md: "40vw", sm: "75vw", xs: "98vw" },
            margin: "100px auto",
            p: 1,
          }}
        >
          <Snackbar
            open={isSuccess}
            autoHideDuration={5000}
            onClose={() => setSuccess(false)}
          >
            <Alert severity="success">{Msg}</Alert>
          </Snackbar>
          <Snackbar
            open={isError}
            autoHideDuration={5000}
            onClose={() => setError(false)}
          >
            <Alert severity="error">{Msg}</Alert>
          </Snackbar>
          <CardContent>
            <form
              onSubmit={isOTPSent ? LoginHandler : sendOTPHandler}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "row", xs: "column" },
                  gap: "10px",
                  width: "100%",
                  alignItems: { md: "center", sm: "", xs: "" },
                }}
              >
                <Typography
                  sx={{
                    width: { xs: "100%", sm: "30%" },
                    fontSize: "1.1rem",
                    fontWeight: "600",
                  }}
                >
                  Enter Email{" "}
                </Typography>
                <TextField
                  placeholder="example@gmail.com"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                  error={
                    validationErrors.email && !validateEmail(formData.email)
                  }
                  helperText={
                    validationErrors.email && !validateEmail(formData.email)
                      ? "Invalid Email"
                      : ""
                  }
                  required
                  disabled={isOTPSent}
                />
              </Box>
              {!isOTPSent && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    gap: "10px",
                    width: "100%",
                    alignItems: { md: "center", sm: "", xs: "" },
                  }}
                >
                  <Typography
                    sx={{ width: "30%", fontSize: "18px", fontWeight: "600" }}
                  >
                    {" "}
                    Password{" "}
                  </Typography>
                  <TextField
                    placeholder="passwordexample123"
                    fullWidth
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={changeHandler}
                    required
                    error={
                      validationErrors.password &&
                      !validatePassword(formData.password)
                    }
                    helperText={
                      validationErrors.password &&
                      !validatePassword(formData.password)
                        ? "Password must be atleast 8 characters and contain atleast one character and one number"
                        : ""
                    }
                  />
                </Box>
              )}
              {isOTPSent && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    gap: "10px",
                    width: "100%",
                    alignItems: { md: "center", sm: "", xs: "" },
                  }}
                >
                  <Typography
                    sx={{ width: "30%", fontSize: "18px", fontWeight: "600" }}
                  >
                    {" "}
                    OTP{" "}
                  </Typography>
                  <TextField
                    name="otp"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={changeHandler}
                    error={!!validationErrors.Scheme}
                    helperText={validationErrors.Scheme}
                    required
                    value={formData.otp}
                  ></TextField>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  height: "10px",
                  justifyContent: "right",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    "&:hover": { textDecoration: "underline" },
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="forgot-password"
                    onClick={() => navigate("/user/forgot-password")}
                  >
                    Forgot Password?
                  </span>
                </Typography>
                {!isOTPSent && (
                  <Typography
                    sx={{
                      "&:hover": { textDecoration: "underline" },
                      cursor: "pointer",
                    }}
                  >
                    <span className="forgot-password" onClick={sendOTPHandler}>
                      Send OTP
                    </span>
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {isOTPSent && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendOTPHandler}
                    sx={{ marginTop: 2, width: "30%" }}
                    disabled={timmer > 0}
                  >
                    {timmer > 0 && !isResend && timmer}
                    {isResend && (
                      <CircularProgress
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                          color: "white",
                        }}
                      />
                    )}{" "}
                    Resend OTP
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={LoginHandler}
                  sx={{ marginTop: 2, width: { xs: "40%", sm: "30%" } }}
                  disabled={isLoading}
                >
                  {isLoading && !isOTPSent && (
                    <CircularProgress
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                        color: "white",
                      }}
                    />
                  )}{" "}
                  {"SUBMIT"}
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography>
                  Don't have an account ?{" "}
                  <a
                    onClick={() => navigate("/signup")}
                    style={{
                      color: "#1876d1",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Sign Up
                  </a>{" "}
                </Typography>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        md={6}
        sm={0}
      
        sx={{
          display: { md: "block", sm: "none", xs: "none" },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xl={8.5} lg={10.5} md={12}>
            <img
              src={loginImage}
              alt="Manage cashflows and forecase cashflows efficiently"
              className="login-image"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Sigin;
