import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import signUpImage from "../../images/signup-image.png";
import * as validators from "../../utils/validators";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import loginImage from "../../images/login-image.png";
import { Paper } from "@material-ui/core";
import { Alert, Box, Snackbar, Typography } from "@mui/material";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ForgotStatus, setForgotStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [email, setEmail] = useState("example@gmail.com");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [isOTPSent, setisOTPSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [isPasswordMatch, SetisPasswordMatch] = useState(true);
  const navigate = useNavigate();

  const ForgotPasswordFun = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/forgot-password`,
        { email }
      )
      .then(({ data }) => {
        console.log(data);
        if (!data.succ) {
          console.log(data);
          setIsLoading(false);
          setErrorMsg(data.message);
          setForgotStatus(true);
          return;
        }
        setIsLoading(false);
        setisOTPSent(true);
        setLinkSent(true);
        setErrorMsg(data.message);
      });
  };

  const VerifyOTP = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/verify-otp`,
        { email, otp, password }
      )
      .then(({ data }) => {
        if (!data.succ) {
          console.log(data);
          setIsLoading(false);
          setErrorMsg(data.message);
          setForgotStatus(true);
          return;
        }
        setIsLoading(false);
        setLinkSent(true);
        setErrorMsg(data.message);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
  };

  const handleSnackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setForgotStatus(false);
    setLinkSent(false);
    setErrorMsg("");
  };

  const HandlerSendLink = () => {
    if (validators.validateEmail(email)) {
      ForgotPasswordFun();
    }
  };

  const HandlerSetPassword = () => {
    if (password !== confirmpassword) {
      SetisPasswordMatch(false);
      return;
    }
    VerifyOTP();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setEmail(value);
  };

  const handleChangeOTP = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setOtp(value);
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setPassword(value);
  };

  const handleChangeConfirmPassword = (event) => {
    SetisPasswordMatch(true);
    event.preventDefault();
    const value = event.target.value;
    setConfirmPassword(value);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
        justifyContent: "center",
        backgroundColor: "#3599cc",
      }}
    >
      <Grid
        item
        md={6}
        sx={{
          width: { md: "42vw", sm: "75vw", xs: "100vw" },
          p: { xs: 0, sm: 1 },
          display:"flex",flexDirection:"column",gap:"4rem"
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "logoFont",
              fontSize: "2rem",
              fontWeight: "500",
            }}
          >
            <span style={{ fontFamily: "logoFont", color: "white" }}>
              KLARFIN
            </span>
          </Typography>
        </Box>
        <Paper elevation={2}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            //className="signup-container"
            sx={{ padding: 2 }}
          >
            <Grid item xl={6} lg={8} md={10} sm={8} xs={10.5}>
              <Grid item xs={12}>
                <div
                  className="signup-heading"
                  style={{ fontSize: "1.4rem", textAlign: "center" }}
                >
                  Forgot Password
                </div>
              </Grid>
              {!isOTPSent ? (
                <Grid
                  container
                  py={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Grid item xs={12} sx={{ width: "100%" }}>
                    <InputLabel className="form-label">
                      Work Email ID*
                    </InputLabel>
                    <TextField
                      placeholder="example@gmail.com"
                      error={!validators.validateEmail(email)}
                      helperText={
                        !validators.validateEmail(email) ? "Invalid Email" : ""
                      }
                      variant="outlined"
                      fullWidth
                      name="email"
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          fontSize: "1.1rem",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "#186090",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ minWidth: "5rem" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        "&:hover": {
                          backgroundColor: "#30a8d8",
                        },
                      }}
                      onClick={HandlerSendLink}
                    >
                      Send OTP to Mail
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <InputLabel className="form-label">
                    Enter OTP Sent To your Mail*
                  </InputLabel>
                  <TextField
                    placeholder="Enter OTP"
                    variant="outlined"
                    fullWidth
                    name="otp"
                    type="text"
                    onChange={handleChangeOTP}
                    InputProps={{
                      style: {
                        fontSize: "0.8rem",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "#186090",
                        },
                      },
                    }}
                  />
                  <InputLabel className="form-label">
                    Enter New Password*
                  </InputLabel>
                  <TextField
                    placeholder="Set Password"
                    error={!validators.validatePassword(password)}
                    helperText={
                      !validators.validatePassword(password)
                        ? "Password must be atleast 8 characters and contain atleast one character and one number"
                        : ""
                    }
                    variant="outlined"
                    fullWidth
                    name="password"
                    type="password"
                    onChange={handleChangePassword}
                    InputProps={{
                      style: {
                        fontSize: "0.8rem",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "#186090",
                        },
                      },
                    }}
                  />
                  <InputLabel className="form-label">
                    Confirm New Password*
                  </InputLabel>
                  <TextField
                    placeholder="Set Password"
                    error={!validators.validatePassword(password)}
                    helperText={
                      isPasswordMatch ? (
                        ""
                      ) : (
                        <Alert severity="error">Password Not Match</Alert>
                      )
                    }
                    variant="outlined"
                    fullWidth
                    name="confirmpassword"
                    type="password"
                    onChange={handleChangeConfirmPassword}
                    InputProps={{
                      style: {
                        fontSize: "0.8rem",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "#186090",
                        },
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        "&:hover": {
                          backgroundColor: "#30a8d8",
                        },
                        width: "200px",
                      }}
                      onClick={HandlerSetPassword}
                    >
                      Set Password
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid
        item
        sm={6}
        //  className="signup-right"
        sx={{
          display: { md: "block", sm: "none", xs: "none" },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xl={8.5} lg={10.5} md={12}>
            <img
              src={loginImage}
              alt="Manage cashflows and forecase cashflows efficiently"
              className="login-image"
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={ForgotStatus}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={linkSent}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Backdrop sx={{ color: "white" }} open={isLoading} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ForgotPassword;
