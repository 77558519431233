import React, { useState } from 'react'
import HorizontalLinearStepper from './stepper'
import Upload_stepper from './Upload_doc/upload_stepper'
import { Typography, Divider, Box } from '@mui/material'

function AddInvestorType({ accessToken, user }) {
    const [isShowH, setShowH] = useState(true);
    const [isShowU, setShowU] = useState(true);
    return (
        <Box sx={{ width:{xs:"100vw" ,md:"81.5vw"},padding:0 }}>
            {isShowH && <Box>
                <HorizontalLinearStepper setShowU={setShowU} accessToken={accessToken} />
            </Box>}
            {isShowH && isShowU && <Box style={{ margin: "3vh" }}>
                <Divider style={{ fontSize: "20px", color: "#1664c0" }}>OR</Divider>
            </Box>}
            {isShowU&&<Box>
                <Upload_stepper setShowH={setShowH} user={user} accessToken={accessToken} />
            </Box>}
        </Box>
    )
}

export default AddInvestorType