import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, CardContent, Typography, Button, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface ApiResponse {
  id: string;
  status: string;
}

interface CreatePaymentProps {
  accessToken: string; // Or `any` if you're unsure about the type
}

const paymentModes = [
  { id: 1, label: 'UPI' },
  { id: 2, label: 'NETBANKING' },
  { id: 3, label: 'NEFT/RTGS' },
];

const CreatePayment: React.FC<CreatePaymentProps> = ({ accessToken }) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const state = location.state as { apiResponse?: ApiResponse }; 
  const apiResponse = state?.apiResponse; 
  const params = useParams();
  console.log('All Params:', params.applicationId);
  
    const apllicationId = params.applicationId;
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<number | null>(null);

 

  const handlePaymentModeClick = (modeId: number) => {
    setSelectedPaymentMode(modeId);
  };

  const handleProceed = async () => {
    if (selectedPaymentMode === null) {
      alert('Please select a payment mode before proceeding.');
      return;
    }

    const paymentMethod = paymentModes.find(mode => mode.id === selectedPaymentMode)?.label.toLowerCase();

    const payload = {
      payment_method: paymentMethod,
      application_id: apllicationId,
      callback_url: "https://www.klarfin.com/"
    };

    try {
      const response = await fetch('http://localhost:8081/v1/user/fixeddeposit/createpayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}` // Add access token if needed
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('API Response:', data);
      
      // Redirect to the redirect_url from the response
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create payment. Please try again.');
    }
  };

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', borderRadius: 4, boxShadow: 3 , mt:'50px'}}>
      <CardContent>
        {/* <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          Payment Information {apllicationId}
        </Typography> */}

        {/* <Typography variant="body1" sx={{ mt: 2 }}>
          ID from URL: {id}
        </Typography> */}

        <Typography variant="h6" component="div" sx={{ mt: 3 }}>
          Select Payment Mode
        </Typography>

        <List>
          {paymentModes.map((mode) => (
            <ListItem key={mode.id} disablePadding>
              <ListItemButton
                onClick={() => handlePaymentModeClick(mode.id)}
                sx={{
                  backgroundColor: selectedPaymentMode === mode.id ? '#E0F7FA' : '#F9FAFB',
                  borderRadius: 2,
                  boxShadow: 0,
                  mb: 1,
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#E0F7FA',
                  },
                }}
              >
                <ListItemText
                  primary={mode.label}
                  primaryTypographyProps={{
                    sx: { color: '#0A2540', fontWeight: 'bold' },
                  }}
                />
                <ArrowForwardIcon sx={{ color: '#4CAF50' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Button
          variant="contained"
          onClick={handleProceed}
          sx={{
            backgroundColor: '#4CAF50',
            color: '#fff',
            width: '100%',
            mt: 2,
            borderRadius: 2,
            '&:hover': {
              backgroundColor: '#45A049',
            },
          }}
        >
          Proceed
        </Button>
      </CardContent>
    </Card>
  );
};

export default CreatePayment;
