import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Box
} from "@mui/material";
import { dateConverter } from "../../../../utils/formatNumber";


function CompanyDetails({ accessToken, handleBack, heading, handleNext, user, capturedData, setCapturedData, Fields }) {
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, SetFormData] = useState<any>({...capturedData,...capturedData.investorType_details})

  const handleChange = event => {
    let { name, value } = event.target;
    if (name == 'dob') {
      const newdob = dateConverter(value);
      value = newdob
    }
    SetFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/companydetails`,
        { ...formData, investorType: capturedData.investorType },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(res => {
        const { data } = res;
        if (!data.succ) {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(data.message);
          return;
        }
        setCapturedData((prev) => ({
          ...prev,
          ["doc_id"]: data.id
        }))
        setIsSuccess(true);
        setMessage(data.message);
        setIsLoading(false);
        setTimeout(() => {
          handleNext();
        }, 2000);
      })
      .catch(error => {
        setIsLoading(false);
        setIsFailure(true);
        setMessage(error.message);
        return;
      });
  };

  return (
    <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Typography variant="subtitle1">{heading}</Typography>
          {Fields?.map((ele, idx) => {
            return <div key={idx} >
              <TextField
                label={ele.label}
                onChange={handleChange}
                type={ele.type}
                // value={formData[ele.name]}
                value={formData[ele.name]}
                name={ele.name}
                required={ele.required}
                variant="outlined"
                margin="normal"
                fullWidth
                focused={ele.type == "date"}
                select={ele.dropdown}
                error={!!validationErrors[ele.name]} // Check if the field has an error
                helperText={validationErrors[ele.name]} /// Display the error message
              >
                {ele.dropdown && ele.value.map((val) =>
                  <MenuItem value={val} >{val}</MenuItem>
                )}
              </TextField>
            </div>
          })}
          {/* 
          <TextField
            label="Company Contact No"
            onChange={handleChange}
            value={formData.phone}
            name='phone'
            required
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.phone} // Check if the field has an error
            helperText={validationErrors.phone} // Display the error message
          />

          <TextField
            label="Company Email"
            onChange={handleChange}
            name='email'
            required
            value={formData.email}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.email} // Check if the field has an error
            helperText={validationErrors.email} // Display the error message
          ></TextField> */}
          <Box sx={{display:"flex",flex:"row",gap:4}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {
                "Back"
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </form>
      </CardContent>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={() => setIsFailure(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default CompanyDetails;
