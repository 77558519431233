import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DeliverProducts = ({
  products,
  selectedProducts,
  setSelectedProducts,
  setCountError,
  total,
  totalQuantity,
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1224, min: 664 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 664, min: 0 },
      items: 1,
    },
  };

  const handleCheckboxChange = (product) => {
    const isProductSelected = selectedProducts.some(
      (selectedProduct) => selectedProduct.sku === product.sku
    );

    if (isProductSelected) {
      setSelectedProducts(
        selectedProducts.filter((p) => p?.sku !== product.sku)
      );
      setCountError("");
    } else {
      let value = +product.redeemWeight + total;
      if (value <= totalQuantity) {
        setSelectedProducts([...selectedProducts, product]);
      } else {
        setCountError("You can not select more then total quantity.");
      }
    }
  };

  return (
    <div>
      <Card sx={{ marginTop: "20px" }}>
        <CardContent>
          <Typography variant="h5" sx={{ fontWeight: "600" }} gutterBottom>
            Products
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {products.map((product) => {
              return (
                <Card sx={{ maxWidth: 345, cursor: "pointer" }}>
                  <CardHeader
                    avatar={
                      <Checkbox
                        name="checked"
                        checked={selectedProducts.some(
                          (selectedProduct) =>
                            selectedProduct.sku === product.sku
                        )}
                        onChange={() => handleCheckboxChange(product)}
                      />
                    }
                    title={product.name}
                    // subheader="September 14, 2016"
                  />
                  <CardContent>
                    {product.productImages.length >= 2 ? (
                      <Carousel
                        responsive={responsive}
                        infinite={true}
                        className="caty__list py-5"
                      >
                        {product.productImages.map((image: any, i: number) => (
                          <CardMedia
                            component="img"
                            height="190"
                            image={`${image.url}`}
                            alt="Paella dish"
                            sx={{ objectFit: "contain" }}
                          />
                        ))}
                      </Carousel>
                    ) : (
                      <CardMedia
                        component="img"
                        height="190"
                        image={`${product.productImages[0].url}`}
                        alt="Paella dish"
                        sx={{ objectFit: "contain" }}
                      />
                    )}

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", paddingTop: "5px" }}
                    >
                      {product.description ?? ""}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeliverProducts;
