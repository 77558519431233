import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";

function UploadCancelCheque({ accessToken }) {
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [message, setMessage] = useState("");
  const { state } = useLocation()
  const [doc_cancelled_cheque, setdoc_cancelled_cheque] = useState("");

  //const [formData, SetFormData] = useState<any>({...capturedData,...capturedData.investorType_details,nominee1_type:"Major"})
  //const [formData, SetFormData] = useState<any>({})

  const handleChange = (event) => {
    let { name, files } = event.target;
    if (name == "doc_cancelled_cheque") {
      setdoc_cancelled_cheque(files[0]);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const FormDatas = new FormData();
    FormDatas.append("doc_cancelled_cheque", doc_cancelled_cheque);
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/uploaddoc`,
        FormDatas,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            //'Content-Type': "multipart/form-data",
            "Content-Type": "application/octet-stream",
          },
          params: { CustomerID: state },
        }
      )
      .then((res) => {
        const { data } = res;
        if (!data.succ) {
          setIsLoading(false);
          setIsFailure(true);
          setMessage(data.message);
          return;
        }
        setIsSuccess(true);
        setMessage(data.message);
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        setIsLoading(false);
        setIsFailure(true);
        setMessage(error.message);
        return;
      });
  };
  const heading = "Upload Individual Documents";
  const Fields = [
    {
      name: "doc_cancelled_cheque",
      type: "file",
      label: "Upload Cancel Cheque",
      dropdown: false,
      required: true,
      isFreezed: false,
      helperText: "Upload in PDF format",
      value: [],
    },
  ];

  return (
    <Card sx={{ maxWidth: 600, margin: "10% auto" }}>
      <CardContent>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Typography variant="subtitle1">{heading}</Typography>
          {Fields?.map((ele, idx) => {
            return (
              <div key={idx}>
                <TextField
                  label={ele.label}
                  onChange={handleChange}
                  type={ele.type}
                  // value={formData[ele.name]}
                  name={ele.name}
                  required={ele.required}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled={ele.isFreezed}
                  focused
                  select={ele.dropdown}
                  error={!!validationErrors[ele.name]} // Check if the field has an error
                  helperText={validationErrors[ele.name] || ele.helperText} /// Display the error message
                >
                  {ele.dropdown &&
                    ele.value.map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                </TextField>
              </div>
            );
          })}

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            fullWidth
            sx={{ marginTop: 2 }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </form>
      </CardContent>
      <Snackbar
        open={isSuccess}
        autoHideDuration={3000}
        onClose={() => setIsSuccess(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="success" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFailure}
        autoHideDuration={3000}
        onClose={() => setIsFailure(false)}
        sx={{ marginBottom: 2 }}
      >
        <Alert severity="error" sx={{ width: "100%" }} className="snack">
          {message}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default UploadCancelCheque;
