import * as React from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CompanyDetails from "./CompanyDetails";
import DirectorsDetails from "./DirectorsDetails";
import ListShareHolders from "./ListShareHolders";
import CompanyDocument from "./CompanyDocument";
import { useLocation, useNavigate } from "react-router-dom";
import { InputFields } from "./InputFields";
import { Paper } from "@mui/material";
import axios from "axios";
import Individual_Kyc from "../../CheckIINactivation/Individual_Kyc";
import Individual_bank from "./Individual/Individual_bank";
import Individual_details from "./Individual/Individual_details";
import Individual_nomniee from "./Individual/Individual_nomniee";
import Individual_eKycReg from "../../CheckIINactivation/Individual_eKycReg";
import Individual_Upload from "./Individual/Individual_Upload";
const steps = ["1.Details of Company", "2.Details of Director", `3.List of Shareholders as on ${new Date().toISOString().slice(0, 10)}`, "4.Company Related Documents"];
//const investorType = ["Company", "Partnership", "Propertorship", "Individual", "Individual_Test"]
const investorType = ["Company", "Partnership", "Propertorship", "Individual"]
const date = new Date().toLocaleDateString();
export default function Upload_stepper({ user, setShowH, accessToken }) {
  const [validationErrors, setValidationErrors] = React.useState<any>({});
  const location: any = useLocation().state;
  const [activeStep, setActiveStep] = React.useState(-1);
  const [stepperName, setStepperName] = React.useState<any>([]);
  const [capturedData, setCapturedData] = React.useState({
    doc_id: "",
    investorType: ""
  });
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const navigate = useNavigate();
  const isStepOptional = (step: number) => {
    return step === 5;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setShowH(false);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const changeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "investorType" && value !== "Individual") {
      const ArraySteps = Object.keys(InputFields[value]);
      // ArraySteps.map((each, idx) => {
      //   if (each.includes("$date")) {
      //     const str = each.split("$date")[0];
      //     ArraySteps[idx] = str + " " + date
      //   }
      // })
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/status`,
          { investorType: value },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then(res => {
          const { data } = res;
          if (!data.succ) {
            console.log(data);
            return;
          }
          setShowH(false);
          const { status, capturedData } = data
          setCapturedData((prev) => ({
            ...prev,
            ...capturedData,
            ["doc_id"]: data.doc_id
          }))

          if (value == "Company") {
            setActiveStep(status);
          } else if (value == "Partnership") {
            setActiveStep(status);
          } else if (value == "Propertorship") {
            if (status == 4) { setActiveStep(3) } else {
              setActiveStep(status)
            }
          } else if (value == "Individual") {
            setCapturedData((prev) => ({
              ...prev,
              ["investorType"]: value
            }))
            setActiveStep(0)
            setStepperName(["Contact Details", "Bank Details", "Nominee details"])
          }
        })
        .catch((error) => {
          console.log("error : ", error)
          return;
        });
      setStepperName([...ArraySteps])
    } else if (name == "investorType" && value == "Individual") {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/status/individual`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then(res => {
          const { data } = res;
          if (!data.succ) {
            setShowH(false);
            setActiveStep(0)
            setStepperName(["Contact Details", "Bank Details", "Nominee details","Upload Document"])
            console.log(data);
            return;
          }
          setShowH(false);
          setActiveStep(data.status)
          console.log(data.data);
          setCapturedData({ ...data.data })
          setStepperName(["Contact Details", "Bank Details", "Nominee details","Upload Document"])
        })
        .catch((error) => {
          console.log("error : ", error)
          return;
        });
      //setStepperName(["Check KYC","e-KYC Registration" , "Contact Details", "Bank Details", "Nominee details"])
    }
    setCapturedData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleReset = () => {
    navigate("/dashboardAdmin/investment");
  };

  const dataCapture = (key, value) => {
    if (Array.isArray(key)) {
      let obj = {};
      key.forEach(each => {
        obj = { ...obj, ...each };
      });
      setCapturedData({ ...capturedData, ...obj });
      return;
    }
    setCapturedData({ ...capturedData, [key]: value });
  };

  return (
    <Box style={{ padding: "1rem" }}>
      {activeStep == -1 ?
        <Box sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }} >
          <Paper elevation={2} sx={{ width: { xs: "90vw", md: "50vw" }, height: "50%", p: 5 }}>
            <Typography variant="h6" gutterBottom sx={{ width: "100%", textAlign: "center" }}>
              Investor Onboarding
            </Typography>
            <TextField
              label="Investor Type"
              name="investorType"
              onChange={changeHandler}
              variant="outlined"
              margin="normal"
              fullWidth
              defaultValue={capturedData.investorType}
              select
              required
              error={!!validationErrors.Fund}
              helperText={validationErrors.Fund}
            >
              {
                investorType.map((ele, idx) => {
                  return <MenuItem key={idx} value={ele} >{ele}</MenuItem>
                })
              }
            </TextField>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleNext}
              fullWidth
              disabled={capturedData.investorType == ""}
              sx={{ marginTop: 2 }}
            >
              Next Step
            </Button>
          </Paper>
        </Box> :
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {stepperName.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === stepperName.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {capturedData.investorType == "Individual" ? "Thank you!, Your are done with all the steps. Please wait for IIN activation from NSE" : "Thank you!, Your are done with all the steps."}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleBack} style={{ backgroundColor: "#1876d1", color: "white" }} >back</Button>
                <Button onClick={handleReset}>Go to list</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box style={{ minHeight: "80%" }}>
                {capturedData.investorType == "Company" &&
                  <Box style={{ marginTop: "4rem" }}>
                    {activeStep == 0 && <CompanyDetails handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 1 && <DirectorsDetails handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 2 && <ListShareHolders handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 3 && <CompanyDocument handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                  </Box>}
                {capturedData.investorType == "Partnership" &&
                  <Box style={{ marginTop: "4rem" }}>
                    {activeStep == 0 && <CompanyDetails handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 1 && <DirectorsDetails handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} setCapturedData={setCapturedData} />}
                    {activeStep == 2 && <ListShareHolders handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 3 && <CompanyDocument handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                  </Box>}
                {capturedData.investorType == "Propertorship" &&
                  <Box style={{ marginTop: "4rem" }}>
                    {activeStep == 0 && <CompanyDetails handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 1 && <DirectorsDetails handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} setCapturedData={setCapturedData} />}
                    {activeStep == 2 && <CompanyDocument handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                  </Box>}
                {/* {capturedData.investorType == "Individual" &&
                  <Box style={{ marginTop: "4rem" }}>
                    {activeStep == 0 && <DirectorsDetails handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} setCapturedData={setCapturedData} accessToken={accessToken} />}
                    {activeStep == 1 && <CompanyDocument handleBack={handleBack} Fields={InputFields[capturedData.investorType][stepperName[activeStep]]} heading={stepperName[activeStep]} capturedData={capturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                  </Box>} */}
                {capturedData.investorType == "Individual" &&
                  <Box style={{ marginTop: "4rem" }}>
                    {/* {activeStep == 0 && <Individual_Kyc handleBack={handleBack} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />} */}
                    {/* {activeStep == 1 && <Individual_eKycReg handleBack={handleBack} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />} */}
                    {activeStep == 0 && <Individual_details handleBack={handleBack} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 1 && <Individual_bank handleBack={handleBack} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 2 && <Individual_nomniee handleBack={handleBack} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                    {activeStep == 3 && <Individual_Upload handleBack={handleBack} capturedData={capturedData} setCapturedData={setCapturedData} user={user} handleNext={handleNext} accessToken={accessToken} />}
                  </Box>}
              </Box>
            </React.Fragment>
          )}
        </Box>
      }
    </Box>
  );
}
