import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  Alert,
  Snackbar,
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./../../../styles/invoice.css"; // External CSS for styling

import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { FormatNumber, FormatNumberRedeem } from "../../../utils/formatNumber";
import Loading from "../../Dashboard/Loading";
import Datefilter from "../../../utils/Datefilter";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReplayIcon from "@mui/icons-material/Replay";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function TransactionDateWise({ accessToken }: any) {
  const [tranx, setTranx] = useState<any>([]);
  const [selectedDate, handleDateChange] = React.useState(null);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpenBank, setIsOpenBank] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const handleDownload = async (id, fileName) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/invoice/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      );

      if (response.data.type !== "application/pdf") {
        const text = await new Response(response.data).text();
        const parsedError = JSON.parse(text);
        if (!parsedError.succ) {
          setError(true);
          setMessage(parsedError.message || "Failed to download file.");
          return;
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Failed to download file.");
    }
  };

  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      renderHeader: () => <strong>{"S.No"}</strong>,
    },
    {
      field: "metalType",
      headerName: "Gold / Silver",
      width: 380,
      renderHeader: () => <strong>{"Metal"}</strong>,
      renderCell: (params) => {
        let { metalType } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {metalType}
          </div>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 240,
      renderHeader: () => <strong>{"Transaction Type"}</strong>,
      renderCell: (params) => {
        let { transaction_type } = params.row;
        // const TransactionType = transaction_type.includes("Purchase") ? "Purchase" : transaction_type.includes("Redemption") ? "Redemption" : "";

        return <div>{transaction_type}</div>;
      },
    },
    {
      field: "UNITS",
      headerName: "Amount",
      width: 180,
      renderHeader: () => <strong>{"Amount / Units"}</strong>,
      renderCell: (params) => {
        const {
          purchase_quantity, // New key for quantity
          purchase_amount, // New key for amount
          sell_quantity,
          sell_amount,
          receive_quantity,
          status,
          purchaseType,
          transaction_type,
          settlement_status,
        } = params.row;

        let valueToDisplay: string = "";

        if (transaction_type === "Buy") {
          valueToDisplay =
            purchaseType === "quantity"
              ? `${FormatNumber(purchase_quantity)} units`
              : `Rs ${FormatNumber(purchase_amount)}`;
        }

        if (
          transaction_type === "Sell" ||
          (transaction_type === "Gift" && status === "Send")
        ) {
          valueToDisplay =
            purchaseType === "quantity"
              ? `${FormatNumber(sell_quantity)} units`
              : `Rs ${FormatNumber(sell_amount)}`;
        }

        if (transaction_type === "Gift" && status === "Receive") {
          valueToDisplay = `${FormatNumber(receive_quantity)} units`;
        }

        if (transaction_type === "Deliver") {
          valueToDisplay = `${FormatNumber(sell_quantity)} units`;
        }

        let statusColor = "green";

        if (status === "Pending") statusColor = "#FDD017";
        else if (status === "Cancel") statusColor = "red";

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{valueToDisplay}</Typography>
            <Typography
              sx={{ color: statusColor, textWrap: "wrap" }}
              variant="caption"
            >
              {status}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "settlement",
      headerName: "Settlement",
      width: 240,
      renderHeader: () => <strong>{"Settlement"}</strong>,
      renderCell: (params) => {
        const { transaction_type, settlement_status } = params.row;

        let settlementStatusColor = "green";

        if (settlement_status === "Pending") settlementStatusColor = "#FDD017";
        else if (settlement_status === "Rejected")
          settlementStatusColor = "red";
        return (
          <div>
            {transaction_type === "Sell" && (
              <div
                style={{
                  minHeight: "50px",
                  alignItems: "center",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center", // Center horizontally
                  color: settlementStatusColor,
                  gap: "5px",
                }}
              >
                {settlement_status}
                {settlement_status === "Rejected" && (
                  <ReplayIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsOpenBank(true)}
                  />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Transaction Date",
      width: 240,
      renderHeader: () => <strong>{"Transaction Date"}</strong>,
      renderCell: (params) => {
        let { createdAt } = params.row;
        createdAt = `${new Date(createdAt).getDate()}/${new Date(
          createdAt
        ).getMonth()}/${new Date(createdAt).getFullYear()}`;
        return (
          <div
            style={{
              minHeight: "50px",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "center", // Center horizontally
            }}
          >
            {createdAt}
          </div>
        );
      },
    },
    {
      field: "invoice",
      headerName: "Invoice",
      // width: 180,
      renderHeader: () => <strong>{"Invoice"}</strong>,
      renderCell: (params) => {
        const { transaction_type, transaction_id, status } = params.row;

        const handlePrint = () => {
          // You can either print the entire page or target a specific element for printing.
          // For simplicity, this prints the entire page:
          window.print();
        };

        return (
          <div>
            {(transaction_type === "Buy" && status === "Success") ||
            (transaction_type === "Deliver" && status !== "Cancel") ? (
              <ReceiptIcon
                onClick={() =>
                  handleDownload(transaction_id, `${transaction_id}.pdf`)
                }
                style={{ cursor: "pointer" }}
              />
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ]);

  const [loading, setLoading] = useState(false);

  const dateConverter = (str) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(str);
    var mnth = date.getMonth();
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${day}-${month[mnth]}-${year}`;
  };

  const [isDisable, SetisDisable] = useState(true);

  const [IntervalDate, setIntervalDate] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const [Interval, setInterval] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const [accountName, setAccountName] = useState<string>("");

  const [account, setAccount] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [ifsc, setIfsc] = useState<string>("");

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getTranxData = async () => {
    setLoading(true);
    const startDate = new Date(IntervalDate.startDate).getTime();
    const endDate = new Date(IntervalDate.endDate).getTime();
    const diff = endDate - startDate;
    const ThirtyDays = 1000 * 60 * 60 * 24 * 30;
    console.log(endDate - startDate);
    if (diff > ThirtyDays) {
      setLoading(false);
      setMessage("Difference between the dates cannot be more than 30 days");
      setError(true);
      return;
    }

    // Adjust the end date to the end of the selected day
    const endOfDay = new Date(IntervalDate.endDate);
    endOfDay.setHours(23, 59, 59, 999);

    try {
      const res = axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/transactions`,
          {
            from_date: new Date(IntervalDate.startDate),
            to_date: endOfDay,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          setLoading(false);
          console.log(data);
          if (!data.succ) {
            setError(true);
            if (data.message == "Selected date difference Exceed 60 days") {
              setMessage(
                "Difference between the dates cannot be more than 60 days"
              );
            } else {
              setMessage(data.message);
            }

            setTranx(data.data);
            return;
          }
          setTranx(data.data);
        });
    } catch {
      setLoading(false);
      setError(true);
      setTranx([]);
      setMessage("Failed to Fetching Data from Server");
      return;
    }
    SetisDisable(true);
  };

  useEffect(() => {
    getTranxData();
  }, []);

  const changeHandler = (name, value) => {
    setIntervalDate((prev) => ({
      ...prev,
      [name]: dateConverter(value),
    }));
  };

  const handleClose = () => {
    setIsOpenBank(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    let bankObj = {
      accountNumber: account,
      accountName: accountName,
      ifscCode: ifsc,
    };

    console.log("bank obj ->", bankObj);

    setIsLoading(true);

    try {
      let response = await axios.put(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/settlement`,
        bankObj,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.succ) {
        setSuccess(true);
        setMessage(response.data.message);
        handleClose();
      } else {
        console.log("Account Details Error ->", response.data.message);

        setError(true);
        setMessage(response.data.message);
        handleClose();
      }
    } catch (error) {
      console.log("Account Details Error ->", error);
      // setError(true);
      // setMessage(error);
    }

    setAccountName("");
    setAccount("");
    setIfsc("");
    setIsLoading(false);
  };

  return (
    <>
      <Grid container spacing={2} xs>
        <Grid item xs={12} sx={{ ml: { xs: 0, md: 4 }, height: "100vh" }}>
          <Snackbar
            open={isError}
            autoHideDuration={4000}
            onClose={() => setError(false)}
          >
            <Alert severity="error">{message}</Alert>
          </Snackbar>
          <AppBar
            sx={{
              backgroundColor: "white",
              marginLeft: { xs: "5%", md: 0 },
              display: "flex",
              width: { xs: "96vw", md: "81.5vw" },
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
            position="static"
            elevation={0}
          >
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
                padding: 0,
              }}
            >
              <Datefilter
                name="startDate"
                label="From Date"
                value={IntervalDate.startDate}
                changeHandler={changeHandler}
                maxDate={Interval.endDate}
                minDate={Interval.startDate}
                inputFormat="dd-MMM-yyyy"
              />
              <Datefilter
                name="endDate"
                label="To Date"
                value={IntervalDate.endDate}
                changeHandler={changeHandler}
                maxDate={Interval.endDate}
                minDate={Interval.startDate}
                inputFormat="dd-MMM-yyyy"
              />
              <Button
                sx={{ marginTop: "-3px", height: "37px" }}
                variant="contained"
                color="primary"
                onClick={getTranxData}
              >
                Search
              </Button>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              height: "85vh",
              display: { xs: "none", sm: "block" },
              width: { xs: "96.5vw", md: "80vw" },
              ml: { xs: 3, sm: 0 },
            }}
          >
            {loading ? (
              <Loading />
            ) : (
              <DataGrid
                sx={{ mt: 1 }}
                getRowHeight={() => "auto"}
                //  hideFooter={true}
                rowsPerPageOptions={[50, 100, 1000]}
                // rows={rows}
                rows={tranx?.map((each: any, idx: number) => {
                  return { ...each, id: idx + 1 };
                })}
                columns={columns.map((each, idx) => {
                  return {
                    id: idx + 1,
                    ...each,
                    headerAlign: "center",
                    align: "center",
                    sx: {
                      size: 2,
                    },
                  };
                })}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      {isOpenBank && (
        <Fragment>
          <Dialog
            fullScreen={fullScreen}
            open={isOpenBank}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Add Bank Details
            </DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmitData}>
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Account Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={account}
                  required
                  error={account.length > 15 ? true : false}
                  helperText={
                    account.length > 15
                      ? "Account number must be less than 15 digits."
                      : ""
                  }
                  onChange={(e) => setAccount(e.target.value)}
                />

                <TextField
                  type="text"
                  id="outlined-basic"
                  label="Account Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={accountName}
                  required
                  onChange={(e) => setAccountName(e.target.value)}
                />

                <TextField
                  type="text"
                  id="outlined-basic"
                  label="Ifac Code"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={ifsc}
                  required
                  onChange={(e) => setIfsc(e.target.value)}
                />
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  )}
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Fragment>
      )}
    </>
  );
}
