import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress, Box, Modal, Typography, Snackbar, Alert, Divider } from '@mui/material';
import axios from 'axios';
import { Clear } from '@mui/icons-material';
import { useAppContext } from '../../../Store/AppContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FormatNumber } from '../../../utils/formatNumber';
function createData(
  key: string,
  value: string,
) {
  return { key, value };
}

function RTGSPayment({ setIsLoading, setOpen, setIsSuccess, setMsg, setFormData, state, setValidationErrors, setIsFailure, isLoading, accessToken, formData, open, validationErrors }) {
  const navigate = useNavigate();
  const [isSucc, setSucc] = useState(false);
  const [isFail, setFail] = useState(false);
  const [Msgs, setMsgs] = useState("");
  const [storeState, dispatch] = useAppContext();

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name == "transfer_date") {
      value = dateConverter(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const dateConverter = (str) => {
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    var date = new Date(str);
    var mnth = (date.getMonth());
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${day}-${month[mnth]}-${year}`;
  }

  const rows = [
    createData('Beneficiary Name', 'NSE Clearing - New Mutual Fund Platform'),
    createData('Beneficiary Bank Name', 'HDFC Bank Ltd'),
    createData('Beneficiary Branch Name', 'Fort, Mumbai'),
    createData('Virtual Account No', `NSEMF2264077${storeState.ACTIVEINVETOR.folio.Folio}`),
    createData('Account Type', 'Current Account'),
    createData('IFSC Code', 'HDFC0000060'),
    createData('Pin code', '400001'),
  ];

  const handleClose = async () => {

    await axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/pendingtrans`, formData,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }).then(res => {
        const { data } = res;
        if (!data.succ) {
          setIsLoading(false);
          setMsg(data.message)
          setIsFailure(true);
          return;
        }
        setIsLoading(false);
        setIsSuccess(true);
        navigate(`/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`, { state: "2" })
        setOpen(false)
      }).catch(({ response }) => {
        setIsLoading(false);
        const { data } = response;
        setIsFailure(true);
        setMsg(data.message);
        setValidationErrors(data.validationErrors);
        return;
      })
    return;
  }
  const handleSubmit = async () => {
    if (formData.utr_no == "") {
      setIsFailure(true);
      setMsg("Please Provide UTR number !!!")
      return;
    }
    setIsLoading(true);
    await axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`, formData,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }).then(res => {
        const { data } = res;
        if (!data.succ) {
          setIsLoading(false);
          setMsg(data.message)
          setFail(true);
          setMsgs(data.message);
          setIsFailure(true);
          return;
        }
        setIsLoading(false);
        setIsSuccess(true);
        setSucc(true)
        setOpen(false)
        setMsgs(`Order submitted successfully for Rs ${formData.instrm_amount}`)
        setMsg(`Order submitted successfully for Rs ${formData.instrm_amount}`)
        setTimeout(() => {
          formData.fundType == "Various funds through NSE" ?
            navigate(`/dashboardAdmin/investment/nse/details/${state.state.folio.Folio}`) :
            navigate(`/dashboardAdmin/investment/details/${state.state.folio.Folio}`)
        }, 3000)

      }).catch(({ response }) => {
        setIsLoading(false);
        const { data } = response;
        setIsFailure(true);
        setMsg(data.message);
        setValidationErrors(data.validationErrors);
        return;
      })
    return;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ onClick: (event) => event.stopPropagation() }}
    >
      <Box sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        width: "80%",
        height: "70%",
        borderRadius: "5px",
        boxShadow: 4,
        padding: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "40px"
      }}>
        <Box sx={{ width: "50%" }}>
          <Typography sx={{ fontWeight: "600", fontSize: "17px", textAlign: "center" }}>
            Please add these beneficiary details for RTGS / NEFT payments (if not already added)
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 200 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        {row.key}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: "50%", height: "100%", textAlign: "center" }}>
          <Snackbar open={isSucc} onClose={handleClose} autoHideDuration={4000} >
            <Alert severity='success' >{Msgs}</Alert>
          </Snackbar>
          <Snackbar open={isFail} onClose={handleClose} autoHideDuration={4000} >
            <Alert severity='error' >{Msgs}</Alert>
          </Snackbar>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }} ><div onClick={handleClose} style={{ cursor: "pointer" }} ><Clear /></div></Box>
          <Typography variant='h5' >RTGS</Typography>
          <Typography id="modal-modal-description">
            Kindly make payment of Rs. {FormatNumber(formData.instrm_amount)} from your bank account
          </Typography>
          <Typography id="modal-modal-description">
            via RTGS and provide us with the UTR here.
          </Typography>
          {/* <TextField
            label="Amount"
            name="instrm_amount"
            onChange={handleChange}
            value={formData.instrm_amount}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            focused
            disabled
            error={!!validationErrors.utr_no}
            helperText={validationErrors.utr_no}
          /> */}
          <TextField
            label="UTR Number"
            name="utr_no"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            error={!!validationErrors.utr_no}
            helperText={validationErrors.utr_no}
          />
          <TextField
            type='date'
            focused
            label="Transfer Date"
            name="transfer_date"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            error={!!validationErrors.transfer_date}
            helperText={validationErrors.transfer_date}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            fullWidth
            onClick={handleSubmit}
            sx={{ marginTop: 2, width: "150px", height: "40px" }}
          >
            {isLoading && <CircularProgress size={13} color="inherit" style={{ marginRight: "15px" }} />} {'INVEST'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RTGSPayment;