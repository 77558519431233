import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Store/AppContext";

interface Payment {
  payment_id: string;
  application_id: string;
  amount: number;
  status: string;
  created_at: string;
  redirect_url: string;
}

interface Application {
  app_id: string;
  amount: number;
  current_status: string;
  created_at: string;
}

interface Member {
  first_name: string;
  last_name: string;
}

interface Transaction {
  payment: Payment;
  application: Application;
  member: Member;
}

const FdTransactions = ({ accessToken }: { accessToken: string }) => {
  const [storeState, dispatch] = useAppContext();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchTransactions = async () => {
    try {
      const token = JSON.parse(
        localStorage.getItem("tokens") || "{}"
      ).accessToken;

      const response = await fetch(
        "http://localhost:8081/v1/user/fixeddeposit/getmembersallpayments",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setTransactions(data.payments);
    } catch (err) {
      setError("Failed to fetch transactions");
      console.error("Error fetching transactions:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          mb: "15px",
          mt: "30px",
          mx: "20px",
          gap: "20px",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            bgcolor: "#007BBD",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "500",
            "&:hover": {
              backgroundColor: "transparent",
              border: "1px solid #007BBD",
              color: "#007BBD",
              borderRadius: "10px",
            },
          }}
          onClick={() =>
            navigate("/dashboardAdmin/investment/nse/details/:folio_id")
          }
        >
          Mutual Funds
        </Button>

        <Button
          sx={{
            textTransform: "none",
            border: "1px solid #007BBD",
            color: "#007BBD",
            borderRadius: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
          onClick={() => navigate("/dashboardAdmin/fd-transactions")}
        >
          Fixed Deposit
        </Button>
      </Stack>

      <Stack
        sx={{ bgcolor: "#318ad6", width: "100%", height: "40px", mt: "20px" }}
      ></Stack>
      <TableContainer component={Paper} style={{ margin: "2rem" }}>
        <Table>
          <TableHead sx={{ bgcolor: "#fff" }}>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "#22223b",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Sr No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "#22223b",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "#22223b",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Application ID
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "#22223b",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "#22223b",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Application Status
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "#22223b",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction, index) => {
              const { application } = transaction;
              return (
                <TableRow key={application.app_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {new Date(application.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{application.app_id}</TableCell>
                  <TableCell>{application.amount}</TableCell>
                  <TableCell>{application.current_status}</TableCell>
                  <TableCell>
                    {application.current_status === "awaiting_payment" && (
                      <Button
                        sx={{
                          bgcolor: "#2fbf71",
                          color: "#fff",
                          borderRadius: "30px",
                          fontSize: "12px",
                          fontWeight: "700",
                          px: "10px",
                          border: "1px solid #2fbf71",

                          "&:hover": {
                            backgroundColor: "transparent",
                            border: "1px solid #2fbf71",
                            color: "#2fbf71",
                            // borderRadius: "10px",
                          },
                        }}
                        onClick={() =>
                          (window.location.href =
                            transaction.payment.redirect_url)
                        }
                      >
                        Make Payment
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FdTransactions;
